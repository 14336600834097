import {
  FcReading,
  FcWorkflow,
  FcGlobe,
  FcLike,
  FcPositiveDynamic,
  FcOrganization,
} from "react-icons/fc";
import { UMF } from "./specialComponents";
import { useContext, useEffect } from "react";
import { app } from "../App";
import { team_members } from "./bitImg";

const Careers = () => {
  const App = useContext(app);
  const isElementVisible = (el) => {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  const checkElementVisibility = () => {
    const p1 = document.querySelector(".p1");
    const p2 = document.querySelector(".p2");
    const p3 = document.querySelector(".p3");
    const p4 = document.querySelector(".p4");
    const p5 = document.querySelector(".p5");
    const p6 = document.querySelector(".p6");
    if (
      p1 !== null &&
      p2 !== null &&
      p3 !== null &&
      p4 !== null &&
      p5 !== null &&
      p6 !== null
    ) {
      if (isElementVisible(p1)) {
        document.querySelector(".pc1").style = "display:block";
      }
      if (isElementVisible(p2)) {
        document.querySelector(".pc2").style = "display:block";
      }
      if (isElementVisible(p3)) {
        document.querySelector(".pc3").style = "display:block";
      }
      if (isElementVisible(p4)) {
        document.querySelector(".pc4").style = "display:block";
      }
      if (isElementVisible(p5)) {
        document.querySelector(".pc5").style = "display:block";
      }
      if (isElementVisible(p6)) {
        document.querySelector(".pc6").style = "display:block";
      }
    }
  };

  window.addEventListener("scroll", checkElementVisibility);
  useEffect(() => {
    App.setCurrentPage("Careers");
  });
  return (
    <>
      <div id="ge1">
        <div className="flexColumnCenter">
          <div id="care2">
            <h1 className="ge2">Our Career Hub</h1>
            <p id="care1">
              Empowering Innovation, Transforming Lives: Our mission is to
              pioneer cutting-edge technology solutions that revolutionize
              industries and enhance daily lives. We're committed to fostering a
              culture of creativity, collaboration, and excellence, where every
              team member plays a vital role in shaping the future of tech.
            </p>
          </div>
          <div id="care15">
            <img alt="" src={team_members} id="care16" />
          </div>
          <button
            id="care3"
            onClick={() => {
              var element = document.querySelector(".cjo").offsetTop - 200;

              if (element !== undefined) {
                window.scrollTo({
                  top: element,
                  behavior: "smooth",
                });
              }
            }}
          >
            Explore Openings
          </button>

          <h1 id="care5">Our Unique Proposition</h1>
          <div id="care8">
            <div id="care6" className="row">
              <div id="care7" className="col-sm-12 col-md-4 p1">
                <div id="care9" className="pc1">
                  <h5 id="care10">
                    <FcReading />
                  </h5>
                  <p id="care11">Continuous Learning</p>
                  <p id="care12">
                    We're committed to your growth. Access workshops,
                    conferences, and opportunities for professional development
                  </p>
                </div>
              </div>{" "}
              <div id="care7" className="col-sm-12 col-md-4 p2">
                <div id="care9" className="pc2">
                  {" "}
                  <h5 id="care10">
                    <FcWorkflow />
                  </h5>
                  <p id="care11">Innovation at the Core</p>
                  <p id="care12">
                    Join a team at the forefront of technological advancements,
                    , pushing the boundaries of what's possible.
                  </p>
                </div>
              </div>{" "}
              <div id="care7" className="col-sm-12 col-md-4 p3">
                <div id="care9" className="pc3">
                  <h5 id="care10">
                    <FcLike />
                  </h5>
                  <p id="care11">Work on Products You Love</p>
                  <p id="care12">
                    Work on products and services that you're passionate about,
                    aligning your career with your interests and values.
                  </p>
                </div>
              </div>{" "}
              <div id="care7" className="col-sm-12 col-md-4 p4">
                <div id="care9" className="pc4">
                  {" "}
                  <h5 id="care10">
                    <FcGlobe />
                  </h5>
                  <p id="care11">Global Exposure</p>
                  <p id="care12">
                    Work with a diverse and international team, gaining exposure
                    to different cultures, perspectives, and problem-solving
                    approaches.
                  </p>
                </div>
              </div>{" "}
              <div id="care7" className="col-sm-12 col-md-4 p5">
                <div id="care9" className="pc5">
                  {" "}
                  <h5 id="care10">
                    <FcPositiveDynamic />
                  </h5>
                  <p id="care11">Growth Opportunities</p>
                  <p id="care12">
                    Benefit from a culture that values personal and professional
                    growth, providing clear paths for advancement and
                    opportunities to take on new challenges.
                  </p>
                </div>
              </div>{" "}
              <div id="care7" className="col-sm-12 col-md-4 p6">
                <div id="care9" className="pc6">
                  {" "}
                  <h5 id="care10">
                    <FcOrganization />
                  </h5>
                  <p id="care11">Networking Opportunities</p>
                  <p id="care12">
                    Connect with industry leaders, experts, and innovators,
                    expanding your professional network and opening doors to new
                    collaborations and opportunities..
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flexColumnCenter">
          <h1 className="ge2 cjo">Current job openings</h1>
          <div id="care13">
            <p id="care14">
              We appreciate your interest in Xapic Technologies. Currently,
              we're not recruiting, but we're always looking for talented
              individuals to join our talent pool. Send your CV to{" "}
              <a href="mailto:people@xapictech.com"> people@xapictech.com</a>{" "}
              and we'll consider you for future opportunities
            </p>
          </div>
        </div>
      </div>

      <UMF />
    </>
  );
};

export default Careers;
