import { useContext, useEffect, useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import { FiArrowRight } from "react-icons/fi";
import { UMF } from "./specialComponents";
import { app } from "../App";
import { useNavigate } from "react-router-dom";
import { TrustedPartners } from "./specialComponents";
import { Vector, CheerfulYoungMan } from "./bitImg";
const Home = () => {
  const App = useContext(app);
  const Navigate = useNavigate();
  useEffect(() => {
    App.setCurrentPage("Home");
  }, []);
  const [serviceRenderedNumber, setServiceRenderedNumber] = useState(1);
  const [ServicesCarouselNumber, setServicesCarouselNumber] = useState(1);

  function isElementVisible(el) {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  function checkElementVisibility() {
    const visionImgContainer = document.getElementById("visionImgContainer");
    if (visionImgContainer !== null) {
      if (isElementVisible(visionImgContainer)) {
        document.getElementById("visionImg").style = "display:block";
        document.getElementById("hmre28").style = "display:block";
      }
    }
  }

  window.addEventListener("scroll", checkElementVisibility);

  useEffect(() => {
    setTimeout(() => {
      if (serviceRenderedNumber < 4) {
        setServiceRenderedNumber((prev) => prev + 1);
      }
      if (serviceRenderedNumber === 4) {
        setServiceRenderedNumber(1);
      }
    }, 1000);
  }, [serviceRenderedNumber]);

  const carouselScroll = () => {
    const carousel = document.getElementById("hmre16");
    const margin = 30;
    const carouselElements = document.getElementById("hmre17");

    let scrollStop;
    carousel.addEventListener("scroll", (e) => {
      clearTimeout(scrollStop);
      scrollStop = setTimeout(() => {
        if (
          carousel.scrollLeft > (carouselElements.offsetWidth + margin) / 2 &&
          carousel.scrollLeft < carouselElements.offsetWidth + margin
        ) {
          setServicesCarouselNumber(2);
        } else if (
          carousel.scrollLeft >
            carouselElements.offsetWidth +
              (carouselElements.offsetWidth + margin * 2) / 2 &&
          carousel.scrollLeft < carouselElements.offsetWidth * 2
        ) {
          setServicesCarouselNumber(3);
        } else if (
          carousel.scrollLeft >
          carouselElements.offsetWidth * 2 +
            (carouselElements.offsetWidth + margin * 3) / 2
        ) {
          setServicesCarouselNumber(4);
        } else {
          if (
            carousel.scrollLeft + margin <
            (carouselElements.offsetWidth + margin) / 2
          ) {
            setServicesCarouselNumber(1);
          } else if (
            carousel.scrollLeft + margin * 2 <
            carouselElements.offsetWidth * 2 + (margin * 3) / 2
          ) {
            setServicesCarouselNumber(2);
          } else if (
            carousel.scrollLeft + margin * 3 <
            carouselElements.offsetWidth * 3 + (margin * 4) / 2
          ) {
            setServicesCarouselNumber(3);
          }
        }
      }, 50);
    });
  };

  const servicesCarouselNavigation = (num) => {
    const carousel = document.getElementById("hmre16");
    const margin = 30;
    const carouselElements = document.getElementById("hmre17");

    if (num === 4) {
      carousel.scrollLeft = carouselElements.offsetWidth * 3 + margin * 3;
    } else if (num === 1) {
      carousel.scrollLeft = 0;
    } else if (num === 2) {
      carousel.scrollLeft = carouselElements.offsetWidth * 1 + margin * 1;
      setServicesCarouselNumber(2);
    } else if (num === 3) {
      carousel.scrollLeft = carouselElements.offsetWidth * 2 + margin * 2;
    }
  };
  useEffect(() => {
    checkElementVisibility();
  }, []);
  return (
    <>
      <div id="homepage">
        <div id="onboarding">
          <div className="row">
            <div className="col-lg-6 col-md-12 " id="onboardingBrief">
              <div id="hmre30" className="flexStart">
                <h1 id="boldOnboardingText">
                  With US, Stay ahead in <br />
                  {serviceRenderedNumber === 1 && (
                    <span style={{ color: "#000485" }}>IT Consulting.</span>
                  )}
                  {serviceRenderedNumber === 2 && (
                    <span style={{ color: "#FE006F" }}>Contracting.</span>
                  )}
                  {serviceRenderedNumber === 3 && (
                    <span style={{ color: "#00BBFF" }}>Talent Sourcing.</span>
                  )}
                  {serviceRenderedNumber === 4 && (
                    <span style={{ color: "#FFC700" }}>IT Training.</span>
                  )}
                </h1>
              </div>
              <li id="onboardingContent">
                XAPIC Technology, an African tech leader, offers IT consulting,
                staffing, and training in web development, design, Cloud DevOps,
                and technical recruitment. We nurture African tech talent for
                global opportunities
              </li>

              <div className="flexStart" id="hmre1">
                <button
                  type="button"
                  id="onboardingGetstartedBtn"
                  onClick={() => {
                    Navigate("/ContactUs");
                    App.instantScrollToTop();
                  }}
                >
                  <span id="hmre44">Get Started </span>
                  <BsArrowRight />
                </button>
                <div className="flexStart">
                  <div className="flexstart">
                    <img alt=""
                      className="trainedTechieProfileImg"
                      src={require("../images/trainedTechie1.png")}
                    />
                    <img alt=""
                      style={{
                        left: "-10px",
                      }}
                      className="trainedTechieProfileImg"
                      src={require("../images/trainedTechie2.png")}
                    />
                    <img alt=""
                      style={{
                        left: "-20px",
                      }}
                      className="trainedTechieProfileImg"
                      src={require("../images/trainedTechie3.png")}
                    />
                  </div>

                  <li id="hmre2">
                    <span id="hmre3">Over 5k+ Techies trained.</span>
                    <br />{" "}
                    <button
                      id="hmre4"
                      onClick={() => {
                        var element = document.getElementById("ucre18");
                        if (element !== undefined) {
                          element.scrollIntoView({ behavior: "instant" });
                        }
                      }}
                    >
                      Read Testimonials.
                    </button>
                  </li>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 flexColumnCenter" id="hmre5">
              <img alt="" src={CheerfulYoungMan} id="hmre6" />
              <div id="hmre35">
                <div id="hmre36">
                  <div id="hmre38">
                    <div id="hmre40">
                      <li id="hmre39" />
                    </div>
                    <div>
                      <li id="hmre41">7k+</li>
                      <li id="hmre42">Satisfied Clients</li>
                    </div>
                  </div>
                </div>
                <div id="hmre37">
                  <div id="hmre38">
                    {" "}
                    <div id="hmre40">
                      <li id="hmre43" />
                    </div>
                    <div>
                      <li id="hmre41">30+</li>
                      <li id="hmre42">IT Professionals</li>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
            <div id="hmre33">
              {" "}
              <TrustedPartners />
            </div>{" "}
          </div>
          

          <div className="row">
            <div className="col-lg-5 col-sm-12 " id="visionImgContainer">
              <img alt="" src={Vector} id="visionImg" />
            </div>
            <div className="col-lg-7 col-sm-12" id="hmre31">
              <div id="hmre28">
                {" "}
                <h1 id="hmre7" className="ge2">
                  Our Vision
                </h1>
                <p id="hmre8">
                  We are a technology workforce development company partnering
                  with businesses to recruit top talent and enhance workforce
                  development. Additionally, we provide digital training for
                  advanced technology skills. Our mission is to bridge the
                  talent gap and drive innovation in the tech sector.
                </p>
              </div>
            </div>
          </div>

          <div id="hmre32">
            <div id="hmre34">
              <h1 id="hmre15" className="ge2">
                Services We Offer
              </h1>

              <div id="hmre16" onScroll={carouselScroll}>
                <div id="hmre17">
                  <div
                    id="hmre18"
                    onClick={() => {
                      Navigate("/ITConsulting");
                      App.instantScrollToTop();
                    }}
                  >
                    <img alt=""
                      src={require("../images/IT_Consulting_Services.png")}
                      id="hmre19"
                    />
                  </div>
                  <div id="hmre20">
                    <div id="hmre25">
                      <h3 id="hmre21">IT Consulting Services</h3>
                      <p id="hmre22">
                        We offer strategic IT guidance and expertise, aligning
                        technology solutions with your business goals to
                        optimize processes and drive success.{" "}
                      </p>
                    </div>
                    <button
                      type="button"
                      id="hmre23"
                      className="hmre45b"
                      onClick={() => {
                        Navigate("/ITConsulting");
                        App.instantScrollToTop();
                      }}
                    >
                      <span className="hmre45"> View Details </span>
                      <span id="hmre24">
                        <FiArrowRight />
                      </span>
                    </button>
                  </div>
                </div>{" "}
                <div id="hmre17">
                  <div
                    id="hmre18"
                    onClick={() => {
                      Navigate("/Contracting");
                      App.instantScrollToTop();
                    }}
                  >
                    <img alt=""
                      src={require("../images/Staffing_&_Contracting_Solutions.png")}
                      id="hmre19"
                    />
                  </div>
                  <div id="hmre20">
                    <div id="hmre25">
                      <h3 id="hmre21">Staffing & Contracting Solutions</h3>
                      <p id="hmre22">
                        Our solutions match your workforce needs with top talent
                        on short-term or contract bases, providing flexible...
                      </p>
                    </div>
                    <button
                      type="button"
                      id="hmre23"
                      className="hmre46b"
                      onClick={() => {
                        Navigate("/Contracting");
                        App.instantScrollToTop();
                      }}
                    >
                      <span className="hmre46"> View Details </span>
                      <span id="hmre24">
                        <FiArrowRight />
                      </span>
                    </button>
                  </div>
                </div>{" "}
                <div id="hmre17">
                  <div
                    id="hmre18"
                    onClick={() => {
                      Navigate("/ITTraining");
                      App.instantScrollToTop();
                    }}
                  >
                    <img alt=""
                      src={require("../images/Training_Programmes.png")}
                      id="hmre19"
                    />
                  </div>
                  <div id="hmre20">
                    <div id="hmre25">
                      <h3 id="hmre21">Training Programmes</h3>
                      <p id="hmre22">
                        Our expert-led training equips you with hands-on skills
                        in full-stack web development, product design, Cloud
                        DevOps Engineering, and Technical ...
                      </p>
                    </div>
                    <button
                      type="button"
                      className="hmre47b"
                      id="hmre23"
                      onClick={() => {
                        Navigate("/ITTraining");
                        App.instantScrollToTop();
                      }}
                    >
                      <span className="hmre47"> View Details </span>
                      <span id="hmre24">
                        <FiArrowRight />
                      </span>
                    </button>
                  </div>
                </div>
                <div id="hmre26">
                  <div
                    id="hmre18"
                    onClick={() => {
                      Navigate("/TalentSourcing");
                      App.instantScrollToTop();
                    }}
                  >
                    <img alt=""
                      src={require("../images/TALENT_SOURCING.png")}
                      id="hmre19"
                    />
                  </div>
                  <div id="hmre20">
                    <div id="hmre25">
                      <h3 id="hmre21">Talent Sourcing</h3>
                      <p id="hmre22">
                        Our recruitment process connects you with qualified
                        professionals who not only have the skills you need but
                        also fit seamlessly into your organizatio...
                      </p>
                    </div>
                    <button
                      type="button"
                      id="hmre23"
                      className="hmre48b"
                      onClick={() => {
                        Navigate("/TalentSourcing");
                        App.instantScrollToTop();
                      }}
                    >
                      <span className="hmre48"> View Details </span>
                      <span id="hmre24">
                        <FiArrowRight />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div id="carouselIndicator">
              {ServicesCarouselNumber === 1 && (
                <li id="activeCarouselIndicator" />
              )}
              {ServicesCarouselNumber !== 1 && (
                <li
                  id="inActiveCarouselIndicator"
                  onClick={() => {
                    servicesCarouselNavigation(1);
                  }}
                />
              )}
              {ServicesCarouselNumber === 2 && (
                <li id="activeCarouselIndicator" />
              )}
              {ServicesCarouselNumber !== 2 && (
                <li
                  id="inActiveCarouselIndicator"
                  onClick={() => {
                    servicesCarouselNavigation(2);
                  }}
                />
              )}
              {ServicesCarouselNumber === 3 && (
                <li id="activeCarouselIndicator" />
              )}
              {ServicesCarouselNumber !== 3 && (
                <li
                  id="inActiveCarouselIndicator3"
                  onClick={() => {
                    servicesCarouselNavigation(3);
                  }}
                />
              )}
              {ServicesCarouselNumber === 4 && (
                <li id="activeCarouselIndicator" />
              )}
              {ServicesCarouselNumber !== 4 && (
                <li
                  id="inActiveCarouselIndicator4"
                  onClick={() => {
                    servicesCarouselNavigation(4);
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <div>
          <UMF />
        </div>
      </div>
    </>
  );
};

export default Home;
