import { Outlet, useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { app } from "../App";
import { useContext } from "react";

export const PrivacyPolicyNav = () => {
  const App = useContext(app);
  const Navigate = useNavigate();
  return (
    <>
      <div id="ppre1" className="fixed-top">
        <button
          id="ppre2"
          onClick={() => {
            App.instantScrollToTop();
            Navigate("/");
          }}
        >
          <IoIosArrowBack />{" "}
        </button>
        <span id="ppre3">Privacy policy</span>
      </div>
      <Outlet />
    </>
  );
};
export const PrivacyPolicy = () => {
  return (
    <>
      <div id="ppre4">
        {" "}
        <h4 id="ppre5"> Privacy Policy for xapic </h4>
        <p id="ppre5"> Effective Date:25,oct 2023</p>
        <p id="ppre6">
          xapic-technologies is committed to protecting the privacy of its
          users. This Privacy Policy describes how we recieve, manage, disclose,
          and secure informations about you. By accessing or using our services,
          you consent to the practices described in this policy.
        </p>{" "}
        <h1 id="ppre5">Information We Collect</h1>
        <h2 id="ppre7">1.1. Automatically Collected Information:</h2>
        <p id="ppre6">
          When you visit our website or use our services, we may collect certain
          information automatically, including but not limited to:
        </p>
        <ul>
          <li id="ppre8">
            <b>Device Information :</b> Such as your IP address, browser type,
            device type, operating system, and other technical information.
          </li>
          <li id="ppre8">
            {" "}
            <b>Usage Information:</b> Including the pages you visit, the
            features you use, the content you view, and the actions you take.{" "}
          </li>
          <li id="ppre8">
            <b>Cookies and Similar Technologies:</b> We may use cookies, web
            beacons, and similar technologies to collect information about your
            interactions with our website.
          </li>
        </ul>
        <h2 id="ppre7">1.2. Provided Information:</h2>
        <p id="ppre6">
          We do not require you to create an account or log in to use our
          services. However, you may choose to provide us with certain
          information voluntarily, such as when you contact us through our
          website or sign up for newsletters.
        </p>
        <h2 id="ppre7">2. How We Use Your Information</h2>
        <p id="ppre6">
          We use the information we collect for the following purposes:
        </p>
        <ul>
          <li id="ppre8">
            {" "}
            <b> Provide and Improve Services:</b> To deliver the services you
            request, and to improve the quality of our products and services.{" "}
          </li>
          <li id="ppre8">
            <b>Analytics and Research:</b> To analyze usage patterns, conduct
            research, and perform statistical analysis.
          </li>
          <li id="ppre8">
            {" "}
            <b>Communicate with You:</b> To respond to your inquiries, send you
            updates, newsletters, and other relevant information.
          </li>
          <li id="ppre8">
            <b>Legal Compliance :</b> To comply with legal requirements and
            protect our rights.{" "}
          </li>
        </ul>
        <h2 id="ppre7">3. Information Sharing </h2>
        <p id="ppre6">
          We do not sell, trade, or rent your information to third parties.
          However, we may share your information with:
        </p>
        <ul>
          <li id="ppre8">
            {" "}
            <b>Service Providers:</b> We may engage third-party service
            providers to assist us in delivering our services. These providers
            are obligated to protect your information.
          </li>
          <li id="ppre8">
            <b>Legal Compliance:</b> We may disclose your information in
            response to a legal request, when required by law, or to protect our
            rights.
          </li>
        </ul>
        <h2 id="ppre7">4. Security Measures</h2>
        <p id="ppre6">
          We take reasonable measures to protect your information from
          unauthorized access, disclosure, alteration, or destruction.
        </p>
        <h2 id="ppre7">5. Your Choices</h2>
        <p id="ppre6">You have the right to:</p>
        <ul>
          <li id="ppre8">
            {" "}
            <b> Access and Correct Your Information: </b>You can request access
            to the personal information we hold about you and request
            corrections.
          </li>
          <li id="ppre8">
            <b>Access and Correct Your Information:</b> You can request access
            to the personal information we hold about you and request
            corrections.
          </li>
        </ul>
        <h2 id="ppre7">6. Third-Party Links</h2>
        <p id="ppre6">
          Our website may contain links to third-party websites. We are not
          responsible for the content or privacy practices of these sites. We
          encourage you to read the privacy policies of any linked websites.
        </p>{" "}
        <h2 id="ppre7">7. Changes to this Privacy Policy</h2>
        <p id="ppre6">
          We may update this Privacy Policy from time to time. The most current
          version will be posted on our website, and the effective date will be
          revised accordingly.
        </p>
        <h2 id="ppre7">8. Contact Us</h2>
        <p id="ppre6">
          If you have any questions or concerns about this Privacy Policy,
          please contact us at:<b>info@xapic.com</b>
        </p>
      </div>
    </>
  );
};
