import { BrowserRouter, Route, Routes } from "react-router-dom";
import MainNavBar from "./components/mainNavBar";
import Home from "./components/home";
import About from "./components/about";
import Portfolio from "./components/portfolio";
import axios from "axios";
import {
  ITConsulting,
  Contracting,
  ITTraining,
  TalentSourcing,
} from "./components/services";
import ContactUs from "./components/contactUs";
import Careers from "./components/careers";
import { PrivacyPolicy, PrivacyPolicyNav } from "./components/privacyPolicy";
import {
  TermsOfServicesNav,
  TermsOfServices,
} from "./components/termsOfServices";
import { createContext, useEffect, useState } from "react";

export const app = createContext();

function App() {
  const instantScrollToTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  };
  const [contactForm, setContactForm] = useState([
    { name: "", email: "", message: "" },
  ]);
  const [SK, setSk] = useState("1234567891011");
  const [currentPage, setCurrentPage] = useState("Home");
  const [currentServicesPage, setCurrentServicesPage] = useState("");
  const [navServicesDropMenu, setNavServicesDropMenu] = useState(false);
  const [blurredBackgroundOverlayStatus, setBlurredBackgroundOverlayStatus] =
    useState("hidden");
  const [darkBackgroundOverlay, setDarkBackgroundOverlay] = useState("hidden");
  const [popupMessage, setPopUpMessage] = useState("hidden");
  const lightTheme = () => {
    const metaTag = document.querySelector('meta[name="theme-color"]');
    metaTag.setAttribute("content", "#fffffff");
  };
  const darkTheme = () => {
    const metaTag = document.querySelector('meta[name="theme-color"]');
    metaTag.setAttribute("content", "#c6c6c6");
  };
  useEffect(() => {
    if (currentPage !== "Services") {
      setCurrentServicesPage("none");
    }
  }, [currentPage]);
  useEffect(() => {
    axios.get(`https://xapicapi.onrender.com/toggleServer`);
  }, []);

  const [teamMembers, setTeamMembers] = useState([
    {
      profileImg: require("./images/team/danielAdemoso.jpg"),
      fullName: "Ademeso Daniel",
      role: "Founder & CEO",
      linkedIn: `https://www.linkedin.com/in/danielademeso/`,
      mail: `danielademeso@xapictech.com`,
    },
    {
      profileImg: require("./images/team/Emmanuel Amodu.jpg"),
      fullName: "Emmanuel Amodu Ojonugwa",
      role: "CTO",
      linkedIn:
        "https://www.linkedin.com/in/emmanuel-amodu?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
      mail: "Emmanuel@xapicconsulting.com",
    },
    {
      profileImg: require("./images/team/hannah_Okuo.jpg"),
      fullName: "Hannah Okuo",
      role: "HR",
      linkedIn: `https://www.linkedin.com/in/hannah-okuo/`,
      mail: `hannahokouo@xapictech.com`,
    },
    {
      profileImg: require("./images/team/ayomide_akintade.jpg"),
      fullName: "Ayomide Akintade",
      role: "Product Manager",
      linkedIn: "https://www.linkedin.com/in/akintade-ayomide/",
      mail: "ayobamitade@xapicconsulting.com",
    },
    {
      profileImg: require("./images/team/Ifeoluwashola_Adarelegbe.jpg"),
      fullName: "Ifeoluwashola Adarelegbe",
      role: "DevOps Engineer",
      linkedIn: `https://www.linkedin.com/in/ifeoluwa-adaralegbe-217118284/`,
      mail: `ifeoluwashol.aadaralegbe@xapictech.com `,
    },
    {
      profileImg: require("./images/team/chukwuyelu_Johnpaul.jpg"),
      fullName: "Chukwuyelu Johnpaul",
      role: "Fullstack Developer",
      mail: "developerjohnpaul@gmail.com",
      linkedIn: "https://www.linkedin.com/in/chukwuyelu-johnpaul-267317273/",
    },
    {
      profileImg: require("./images/team/Udeh Happy.jpg"),
      fullName: "Udeh Happy",
      role: "Product Designer",
      linkedIn: `https://www.linkedin.com/in/happy-kennedy/`,
      mail: `happyudeh@xapictech.com`,
    },
    {
      profileImg: require("./images/team/Umoh Promise.jpg"),
      fullName: "Umoh Promise",
      role: "Assistant Program Manager",
      linkedIn: "https://www.linkedin.com/in/promise-umoh-992701224/",
      mail: "promiseumoh@xapictech.com",
    },

    {
      profileImg: require("./images/team/Imafidon_Kenneth.png"),
      fullName: "Imafidor Kenneth",
      role: "Brand Design Director",
      linkedIn: "https://www.linkedin.com/in/ken-imafidor/",
    },
    {
      profileImg: require("./images/team/Goodnews_Atang-min.jpg"),
      fullName: "Goodnews Atang",
      role: "Graphic Designer",
      linkedIn: "https://www.linkedin.com/in/goodnews-atang",
      mail: "atanggoodnews@xapicconsulting.com",
    },
  ]);

  const [teamLeads, setTeamLeads] = useState([
    {
      profileImg: require("./images/team/danielAdemoso.jpg"),
      fullName: "Ademeso Daniel",
      team: "Founder & CEO",
      briefTeamInfo: `Over 8 years in IT: SRE, Cloud, DevOps & System Engineering.
       Skilled in Linux, Python, automation. Expert in AWS, Azure, GCP, passionate
        about driving tech innovation and nurturing African talent for global opportunities.
         Worked at Aleo, Binance, Andela, Adeva, etc.`,
      linkedIn: `https://www.linkedin.com/in/danielademeso/`,
      mail: `danielademeso@xapictech.com`,
    },
 {
      profileImg: require("./images/team/Emmanuel Amodu.jpg"),
      fullName: "Emmanuel Amodu Ojonugwa",
      team: "CTO",
      briefTeamInfo: `With over a decade of Engineering expertise and five years 
      specializing in Blockchain, Emmanuel held leadership roles at Patricia, Binance,
       and Token Metric, serving as Head of Engineering/Engineering Manager.`,
      linkedIn:
        "https://www.linkedin.com/in/emmanuel-amodu?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    mail:"Emmanuel@xapicconsulting.com"
      },
    {
      profileImg: require("./images/team/hannah_Okuo.jpg"),
      fullName: "Hannah Okuo",
      team: "HR",
      briefTeamInfo: `Dedicated HR professional with over 5 years of experience in various
       HR functions, including recruitment, employee relations, performance management,
       and training.
       Proven ability to develop and implement HR strategies that align with business 
       objectives and drive organizational success.`,
      linkedIn: `https://www.linkedin.com/in/hannah-okuo/`,
      mail: `hannahokouo@xapictech.com`,
    },

   
    {
      profileImg: require("./images/team/ayomide_akintade.jpg"),
      fullName: "Ayomide Akintade",
      team: "Product Manager",
      briefTeamInfo: `Committed to crafting products that seamlessly align with user 
      expectations while driving business success. With a proven track record of leading 
      cross-functional teams, conducting thorough market research and validation, 
      and delivering impactful products,  ensuring that every project exceeds expectations.`,
      linkedIn: "https://www.linkedin.com/in/akintade-ayomide/",
      mail: "ayobamitade@xapicconsulting.com",
    },

    {
      profileImg: require("./images/team/Ifeoluwashola_Adarelegbe2.jpg"),
      fullName: "Ifeoluwashola Adarelegbe",
      team: "Team Lead,DevOps Engineer",
      briefTeamInfo: `Experienced DevOps Engineer skilled in automating workflows and 
      optimizing software development. Proficient in CI/CD, containerization, and cloud
       platforms, with a strong focus on enhancing efficiency and system reliability`,
      linkedIn: `https://www.linkedin.com/in/ifeoluwa-adaralegbe-217118284/`,
      mail: `ifeoluwashol.aadaralegbe@xapictech.com `,
    },

    {
      profileImg: require("./images/team/chukwuyelu_Johnpaul.jpg"),
      fullName: "Chukwuyelu Johnpaul",
      team: "Team Lead,Fullstack Developer",
      briefTeamInfo: `An highly proficient software engineer , skilled in crafting seamless, 
      user-centric digital experiences with proficiency in HTML, CSS, JavaScript,j-query, React,
       Node.js/Express, SQL, and MySQL. Possesses and practically apply the ability to convert 
        ideas and complex technical requirements in to a very elegant ,reliable, and efficient 
       software application`,
      mail: "developerjohnpaul@gmail.com",
      linkedIn: "https://www.linkedin.com/in/chukwuyelu-johnpaul-267317273/",
    },
  ]);

  return (
    <app.Provider
      value={{
        setCurrentPage: setCurrentPage,
        currentPage: currentPage,
        setBlurredBackgroundOverlayStatus: setBlurredBackgroundOverlayStatus,
        blurredBackgroundOverlayStatus: blurredBackgroundOverlayStatus,
        setTeamMembers: setTeamMembers,
        teamMembers: teamMembers,
        instantScrollToTop: instantScrollToTop,
        setSk: setSk,
        SK: SK,
        setNavServicesDropMenu: setNavServicesDropMenu,
        navServicesDropMenu: navServicesDropMenu,
        currentServicesPage: currentServicesPage,
        setCurrentServicesPage: setCurrentServicesPage,
        setTeamLeads: setTeamLeads,
        teamLeads: teamLeads,
        setContactForm: setContactForm,
        contactForm: contactForm,
        setDarkBackgroundOverlay: setDarkBackgroundOverlay,
        darkBackgroundOverlay: darkBackgroundOverlay,
        popupMessage: popupMessage,
        setPopUpMessage: setPopUpMessage,
        darkTheme: darkTheme,
        lightTheme: lightTheme,
      }}
    >
      {" "}
      <div id="App">
        {darkBackgroundOverlay === "visible" && (
          <>
            {" "}
            <div
              id="ge7"
              onClick={() => {
                setDarkBackgroundOverlay("hidden");
                setPopUpMessage("none");
                lightTheme();
              }}
            />
          </>
        )}
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<MainNavBar />}>
              <Route index element={<Home />} />
              <Route path="/About" element={<About />} />
              <Route path="/ITConsulting" element={<ITConsulting />} />
              <Route path="/Contracting" element={<Contracting />} />
              <Route path="/TalentSourcing" element={<TalentSourcing />} />
              <Route path="/ITTraining" element={<ITTraining />} />
              <Route path="/Portfolio" element={<Portfolio />} />
              <Route path="/ContactUs" element={<ContactUs />} />
              <Route path="/Careers" element={<Careers />} />
            </Route>
            <Route path="/PrivacyPolicy" element={<PrivacyPolicyNav />}>
              <Route index element={<PrivacyPolicy />} />
            </Route>
            <Route path="/TermsOfServices" element={<TermsOfServicesNav />}>
              <Route index element={<TermsOfServices />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </div>
    </app.Provider>
  );
}

export default App;
