import { FaWhatsappSquare} from "react-icons/fa";
import { UMF } from "./specialComponents";
import { useContext, useEffect, useState } from "react";
import { app } from "../App";
import { BsEnvelopeCheckFill } from "react-icons/bs";
import { ImOffice } from "react-icons/im";
import { BiSolidError } from "react-icons/bi";
import { chat } from "./bitImg";
import axios from "axios";
const ContactUs = () => {
  const App = useContext(app);
  const [invalidInput, setInvalidInput] = useState("none");
  const [invalidInputWarningText, setInvalidInputWarningText] =
    useState("none");
  const [pendingSubmission, setPendingSubmission] = useState(false);
  const [tempSavedFormDetails, setTempSavedFormDetails] = useState({});
  useEffect(() => {
    axios.get(`https://xapicapi.onrender.com/toggleServer`);
  }, []);
  useEffect(() => {
    App.setCurrentPage("ContactUs");
  }, []);

  const submitContactForm = (e) => {
    e.preventDefault();
    if (App.contactForm[0].name === "") {
      setInvalidInput("name");
      setInvalidInputWarningText("emptyNameInput");
    } else if (App.contactForm[0].email === "") {
      setInvalidInput("email");
      setInvalidInputWarningText("emptyEmailInput");
    } else if (!App.contactForm[0].email.endsWith("@gmail.com")) {
      setInvalidInput("email");
      setInvalidInputWarningText("invalidEmail");
    } else if (App.contactForm[0].message === "") {
      setInvalidInput("message");
      setInvalidInputWarningText("emptyMessage");
    } else {
      setPendingSubmission(true);
      setTempSavedFormDetails(App.contactForm[0]);
      setInvalidInput("none");

      axios
        .post(`https://xapicapi.onrender.com/xapicMailBox`, App.contactForm[0])
        .then(
          (res) => {
            App.setDarkBackgroundOverlay("visible");
            App.setPopUpMessage("contactFormReceived");
            setPendingSubmission(false);
            App.darkTheme();
            setTimeout(() => {
              App.setContactForm([{ name: "", email: "", message: "" }]);
            }, 500);
          },
          (err) => {
            App.setDarkBackgroundOverlay("visible");
            App.setPopUpMessage("contactFormNotReceived");
            App.darkTheme();
            setPendingSubmission(false);
          }
        );
    }
  };

  return (
    <>
      {App.popupMessage === "contactFormReceived" && (
        <div id="ge8">
          <h5 id="ge9">
            Message recieved
            <span id="ge12">
              {" "}
              <BsEnvelopeCheckFill />
            </span>
          </h5>
          <p id="ge10">
            Dear {tempSavedFormDetails.name}, We're pleased to inform you that
            your message has been received. Our team is currently reviewing your
            request and you would get a response shortly via the provided email
            address .
            <br />
            Have a xappy day.
          </p>
          <button
            id="ge11"
            type="button"
            onClick={() => {
              App.setDarkBackgroundOverlay("hidden");
              App.setPopUpMessage("none");
              App.lightTheme();
            }}
          >
            close
          </button>
        </div>
      )}
      {App.popupMessage === "contactFormNotReceived" && (
        <div id="ge8">
          <h5 id="ge9">
            Error sending message
            <span id="ge13">
              {" "}
              <BiSolidError />
            </span>
          </h5>
          <p id="ge10">
            Thank you for your message. We regret to inform you that your
            message was not delivered . We apologize for any inconvenience this
            may cause. Our team is working diligently to rectify this
            situation.Be sure to have a stable internet connection and try again
            in a bit,if the error persist contact our support team directly at
            info@gmail.com
            <br />
            Wishing you a xappy day.
          </p>
          <button
            id="ge11"
            type="button"
            onClick={() => {
              App.setDarkBackgroundOverlay("hidden");
              App.setPopUpMessage("none");
              App.lightTheme();
            }}
          >
            close
          </button>
        </div>
      )}

      <div id="ge1">
        <div className="row" id="cure1">
          <div className="col-sm-12 col-lg-6" id="cure2">
            <div>
              {" "}
              <h1 className="ge2" id="cure20">
                Send Us a Message
              </h1>{" "}
              <div id="cure4">
                <img alt="" src={chat} id="cure5" />
              </div>
              <div id="cure6">
                <h5 id="cure7">Contact</h5>
                <li id="cure8">
                  <ImOffice />
                </li>
                <li id="cure9">
                  <b>Lagos :</b> 2nd floor, Leasing House, C & I Leasing Drive,
                  Off Admiralty way, Lekki Phase 1, Lagos
                </li>
                <li id="cure9">
                  <b>Akure:</b> Xapic Technologies Building, Opposite State
                  High Court, Arakale Road, Akure
                </li>
                <div id="cure10">
                  <li id="cure12">
                    {" "}
                    <span id="cure13">
                      <FaWhatsappSquare />
                    </span>{" "}
                    +2348167001859
                  </li>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-lg-6" id="cure3">
            <div id="cure21">
              {" "}
              <h3 id="cure14">Send Us a Message.</h3>
              <form onSubmit={submitContactForm}>
                <div id="cure15">
                  <li id="cure16">Your Name</li>
                  {invalidInput !== "name" && (
                    <input
                      type="text"
                      id="cure17"
                      value={App.contactForm[0].name}
                      onChange={(e) => {
                        App.setContactForm((value) => {
                          return value.map((val, ind) => {
                            return { ...val, name: e.target.value };
                          });
                        });
                      }}
                    />
                  )}
                  {invalidInput === "name" && (
                    <>
                      {" "}
                      <input
                        type="text"
                        id="cure23"
                        value={App.contactForm[0].name}
                        onChange={(e) => {
                          App.setContactForm((value) => {
                            return value.map((val, ind) => {
                              return { ...val, name: e.target.value };
                            });
                          });
                        }}
                      />
                      {invalidInputWarningText === "emptyNameInput" && (
                        <p id="cure24">Name cannot be empty</p>
                      )}
                    </>
                  )}
                </div>
                <div id="cure15">
                  <li id="cure16">Email address</li>
                  {invalidInput !== "email" && (
                    <input
                      type="email"
                      id="cure17"
                      value={App.contactForm[0].email}
                      onChange={(e) => {
                        App.setContactForm((value) => {
                          return value.map((val, ind) => {
                            return { ...val, email: e.target.value };
                          });
                        });
                      }}
                    />
                  )}
                  {invalidInput === "email" && (
                    <>
                      {" "}
                      <input
                        type="email"
                        id="cure23"
                        value={App.contactForm[0].email}
                        onChange={(e) => {
                          App.setContactForm((value) => {
                            return value.map((val, ind) => {
                              return { ...val, email: e.target.value };
                            });
                          });
                        }}
                      />
                      {invalidInputWarningText === "emptyEmailInput" && (
                        <p id="cure24">Email address cannot be empty</p>
                      )}
                      {invalidInputWarningText === "invalidEmail" && (
                        <p id="cure24">
                          Enter a valid email address ending with @gmail.com
                        </p>
                      )}
                    </>
                  )}
                </div>
                <div id="cure15">
                  <li id="cure16">Message</li>
                  {invalidInput !== "message" && (
                    <textarea
                      type="text"
                      id="cure18"
                      value={App.contactForm[0].message}
                      onChange={(e) => {
                        App.setContactForm((value) => {
                          return value.map((val, ind) => {
                            return { ...val, message: e.target.value };
                          });
                        });
                      }}
                    />
                  )}
                  {invalidInput === "message" && (
                    <>
                      <textarea
                        type="text"
                        id="cure25"
                        value={App.contactForm[0].message}
                        onChange={(e) => {
                          App.setContactForm((value) => {
                            return value.map((val, ind) => {
                              return { ...val, message: e.target.value };
                            });
                          });
                        }}
                      />
                      {invalidInputWarningText === "emptyMessage" && (
                        <p id="cure24">Message cannot be empty</p>
                      )}
                    </>
                  )}
                </div>
                <div className="flexCenter" id="cure22">
                  {!pendingSubmission && (
                    <button type="submit" id="cure19">
                      Send
                    </button>
                  )}
                  {pendingSubmission && (
                    <button type="button" id="cure19">
                      <span
                        className="spinner-border"
                        id="cure26"
                        role="status"
                      ></span>
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <UMF />
    </>
  );
};

export default ContactUs;
