import { Outlet, useNavigate } from "react-router-dom";
import { app } from "../App";
import { useContext, useEffect, useState } from "react";
import { CgMenu, CgClose } from "react-icons/cg";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import CryptoJS from "crypto-js";
const MainNavBar = () => {

  const App = useContext(app);
  const Navigate = useNavigate();
  const [navMenu, setNavMenu] = useState(false);
  useEffect(() => {
    if (navMenu === false) {
      App.setNavServicesDropMenu(false);
    }
  }, [navMenu]);
  const navDropNavigator = (page) => {
    App.setCurrentPage(page);
    localStorage.setItem(
      "CP",
      CryptoJS.AES.encrypt(JSON.stringify(page), App.SK).toString()
    );
    App.instantScrollToTop();
    setNavMenu(false);
    App.setBlurredBackgroundOverlayStatus("hidden");
  };

  const navNavigator = (page) => {
    App.setCurrentPage(page);
    localStorage.setItem(
      "CP",
      CryptoJS.AES.encrypt(JSON.stringify(page), App.SK).toString()
    );
    App.instantScrollToTop();
    setNavMenu(false);
    App.setBlurredBackgroundOverlayStatus("hidden");
    App.setNavServicesDropMenu(false);
  };
  const navigateServices = (e) => {
    App.setCurrentServicesPage(e);
    App.setCurrentPage("Services");
    App.setBlurredBackgroundOverlayStatus("hidden");
    App.setNavServicesDropMenu(false);
    setNavMenu(false);
    App.instantScrollToTop();
  };

  const toggleServiceDropdown = () => {
    App.setNavServicesDropMenu((prev) => !prev);
    if (
      App.blurredBackgroundOverlayStatus === "visible" &&
      App.setNavMenu === false
    ) {
      App.setBlurredBackgroundOverlayStatus("hidden");
    }
    if (App.blurredBackgroundOverlayStatus === "hidden") {
      App.setBlurredBackgroundOverlayStatus("visible");
    }
  };

  return (
    <>
      <div id="mainNavBar">
        {App.darkBackgroundOverlay === "visible" && (
          <>
            {" "}
            <div
              id="ge6"
              onClick={() => {
                App.setDarkBackgroundOverlay("hidden");
                App.setPopUpMessage("none");
                App.lightTheme();
              }}
            />
          </>
        )}
        <img
          alt=""
          src={require("../images/Xapic Logo - Full Colour with Blue.png")}
          id="mainNavCompanyLogo"
          onClick={() => {
            navNavigator("Home");
            Navigate("/");
          }}
        />

        <div className="flexStart" id="mainNavRoutesContainer">
          {App.currentPage === "Home" && (
            <button
              className="activeMainNavRoutes"
              onClick={() => {
                navNavigator("Home");
                Navigate("/");
              }}
            >
              Home
            </button>
          )}

          {App.currentPage !== "Home" && (
            <button
              className="mainNavRoutes"
              onClick={() => {
                navNavigator("Home");
                Navigate("/");
              }}
            >
              Home
            </button>
          )}

          {App.currentPage === "About" && (
            <button
              className="activeMainNavRoutes"
              onClick={() => {
                navNavigator("About");
                Navigate("/About");
              }}
            >
              About
            </button>
          )}
          {App.currentPage !== "About" && (
            <button
              className="mainNavRoutes"
              onClick={() => {
                navNavigator("About");
                Navigate("/About");
              }}
            >
              About
            </button>
          )}
          {App.currentPage === "Services" && (
            <button
              className="activeMainNavRoutes"
              onClick={() => {
                toggleServiceDropdown();
                if (App.blurredBackgroundOverlayStatus === "visible") {
                  App.setBlurredBackgroundOverlayStatus("hidden");
                } else {
                  App.setBlurredBackgroundOverlayStatus("visible");
                }
              }}
            >
              Services
            </button>
          )}

          {App.currentPage !== "Services" && (
            <button
              className="mainNavRoutes"
              onClick={() => {
                toggleServiceDropdown();
                if (App.blurredBackgroundOverlayStatus === "visible") {
                  App.setBlurredBackgroundOverlayStatus("hidden");
                } else {
                  App.setBlurredBackgroundOverlayStatus("visible");
                }
              }}
            >
              Services
            </button>
          )}
          {App.navServicesDropMenu && (
            <div id="NavServicesDropMenu">
              <li id="sdl">
                {App.currentServicesPage !== "ITConsulting" && (
                  <button
                    id="sdl2"
                    onClick={() => {
                      navigateServices("ITConsulting");
                      Navigate("/ITConsulting");
                    }}
                  >
                    IT Consulting
                  </button>
                )}{" "}
                {App.currentServicesPage === "ITConsulting" && (
                  <button
                    id="sdl3"
                    onClick={() => {
                      navigateServices("ITConsulting");
                      Navigate("/ITConsulting");
                    }}
                  >
                    IT Consulting
                  </button>
                )}
              </li>
              <li id="sdl">
                {App.currentServicesPage !== "Contracting" && (
                  <button
                    id="sdl2"
                    onClick={() => {
                      navigateServices("Contracting");
                      Navigate("/Contracting");
                    }}
                  >
                    Contracting
                  </button>
                )}{" "}
                {App.currentServicesPage === "Contracting" && (
                  <button
                    id="sdl3"
                    onClick={() => {
                      navigateServices("Contracting");
                      Navigate("/Contracting");
                    }}
                  >
                    Contracting
                  </button>
                )}
              </li>
              <li id="sdl">
                {App.currentServicesPage !== "TalentSourcing" && (
                  <button
                    id="sdl2"
                    onClick={() => {
                      navigateServices("TalentSourcing");
                      Navigate("/TalentSourcing");
                    }}
                  >
                    Talent Sourcing
                  </button>
                )}{" "}
                {App.currentServicesPage === "TalentSourcing" && (
                  <button
                    id="sdl3"
                    onClick={() => {
                      navigateServices("TalentSourcing");
                      Navigate("/TalentSourcing");
                    }}
                  >
                    Talent Sourcing
                  </button>
                )}
              </li>
              <li id="sdl">
                {App.currentServicesPage !== "ITTraining" && (
                  <button
                    id="sdl2"
                    onClick={() => {
                      navigateServices("ITTraining");
                      Navigate("/ITTraining");
                    }}
                  >
                    IT Training
                  </button>
                )}{" "}
                {App.currentServicesPage === "ITTraining" && (
                  <button
                    id="sdl3"
                    onClick={() => {
                      navigateServices("ITTraining");
                      Navigate("/ITTraining");
                    }}
                  >
                    IT Training
                  </button>
                )}
              </li>
            </div>
          )}
          {App.currentPage === "Portfolio" && (
            <button
              className="activeMainNavRoutes"
              onClick={() => {
                navNavigator("Portfolio");
                Navigate("/Portfolio");
              }}
            >
              Portfolio
            </button>
          )}
          {App.currentPage !== "Portfolio" && (
            <button
              className="mainNavRoutes"
              onClick={() => {
                navNavigator("Portfolio");
                Navigate("/Portfolio");
              }}
            >
              Portfolio
            </button>
          )}
        </div>

        <div className="flexStart">
          {" "}
          <button
            id="mainNavContactUsBtn"
            onClick={() => {
              navNavigator("ContactUs");
              Navigate("/ContactUs");
            }}
          >
            Contact Us
          </button>
          <button
            id="mainNavTogglerBtn"
            onClick={() => {
              setNavMenu((prev) => !prev);
              if (navMenu === true) {
                App.setBlurredBackgroundOverlayStatus("hidden");
              }
              if (navMenu === false) {
                App.setBlurredBackgroundOverlayStatus("visible");
              }
            }}
          >
            {!navMenu && <CgMenu />}
            {navMenu && <CgClose />}
          </button>
        </div>

        {navMenu && (
          <div id="mainNavDropMenu">
            {App.currentPage === "Home" && (
              <button
                id="activeMainNavDropMenuLinks"
                onClick={() => {
                  navDropNavigator("Home");
                  Navigate("/");
                }}
              >
                <span>Home</span>{" "}
              </button>
            )}
            {App.currentPage !== "Home" && (
              <button
                id="eachMainNavDropMenuLinks"
                onClick={() => {
                  navDropNavigator("Home");
                  Navigate("/");
                }}
              >
                <span>Home</span>{" "}
              </button>
            )}
            {App.currentPage === "About" && (
              <button
                id="activeMainNavDropMenuLinks"
                onClick={() => {
                  navDropNavigator("About");
                  Navigate("/About");
                }}
              >
                <span>About</span>{" "}
              </button>
            )}
            {App.currentPage !== "About" && (
              <button
                id="eachMainNavDropMenuLinks"
                onClick={() => {
                  navDropNavigator("About");
                  Navigate("/About");
                }}
              >
                <span>About</span>
              </button>
            )}
            {App.currentPage === "Services" && (
              <button
                id="activeMainNavDropMenuLinks"
                onClick={() => {
                  toggleServiceDropdown();
                }}
              >
                <span>Services</span>{" "}
                {!App.navServicesDropMenu && (
                  <span>
                    <FaChevronDown />
                  </span>
                )}
                {App.navServicesDropMenu && (
                  <span>
                    <FaChevronUp />
                  </span>
                )}
              </button>
            )}
            {App.currentPage !== "Services" && (
              <button
                id="eachMainNavDropMenuLinks"
                onClick={() => {
                  toggleServiceDropdown();
                }}
              >
                <span>Services</span>
                {!App.navServicesDropMenu && (
                  <span>
                    <FaChevronDown />
                  </span>
                )}
                {App.navServicesDropMenu && (
                  <span>
                    <FaChevronUp />
                  </span>
                )}
              </button>
            )}

            {App.navServicesDropMenu && (
              <div id="navDNavServiceDropdown">
                {App.currentServicesPage !== "ITConsulting" && (
                  <button
                    id="sdl5"
                    onClick={() => {
                      navigateServices("ITConsulting");
                      Navigate("/ITConsulting");
                    }}
                  >
                    IT Consulting
                  </button>
                )}{" "}
                {App.currentServicesPage === "ITConsulting" && (
                  <button
                    id="sdl6"
                    onClick={() => {
                      navigateServices("ITConsulting");
                      Navigate("/ITConsulting");
                    }}
                  >
                    IT Consulting
                  </button>
                )}
                {App.currentServicesPage !== "Contracting" && (
                  <button
                    id="sdl5"
                    onClick={() => {
                      navigateServices("Contracting");
                      Navigate("/Contracting");
                    }}
                  >
                    Contracting
                  </button>
                )}{" "}
                {App.currentServicesPage === "Contracting" && (
                  <button
                    id="sdl6"
                    onClick={() => {
                      navigateServices("Contracting");
                      Navigate("/Contracting");
                    }}
                  >
                    Contracting
                  </button>
                )}
                {App.currentServicesPage !== "TalentSourcing" && (
                  <button
                    id="sdl5"
                    onClick={() => {
                      navigateServices("TalentSourcing");
                      Navigate("/TalentSourcing");
                    }}
                  >
                    Talent Sourcing
                  </button>
                )}{" "}
                {App.currentServicesPage === "TalentSourcing" && (
                  <button
                    id="sdl6"
                    onClick={() => {
                      navigateServices("TalentSourcing");
                      Navigate("/TalentSourcing");
                    }}
                  >
                    Talent Sourcing
                  </button>
                )}
                {App.currentServicesPage !== "ITTraining" && (
                  <button
                    id="sdl5"
                    onClick={() => {
                      navigateServices("ITTraining");
                      Navigate("/ITTraining");
                    }}
                  >
                    IT Training
                  </button>
                )}{" "}
                {App.currentServicesPage === "ITTraining" && (
                  <button
                    id="sdl6"
                    onClick={() => {
                      navigateServices("ITTraining");
                      Navigate("/ITTraining");
                    }}
                  >
                    IT Training
                  </button>
                )}
              </div>
            )}
            {App.currentPage === "Portfolio" && (
              <button
                id="activeMainNavDropMenuLinks"
                onClick={() => {
                  navDropNavigator("Portfolio");
                  Navigate("/Portfolio");
                }}
              >
                <span>Portfolio</span>{" "}
              </button>
            )}
            {App.currentPage !== "Portfolio" && (
              <button
                id="eachMainNavDropMenuLinks"
                onClick={() => {
                  navDropNavigator("Portfolio");
                  Navigate("/Portfolio");
                }}
              >
                <span>Portfolio</span>{" "}
              </button>
            )}

            <button
              id="mainNavDropContactUsBtn"
              onClick={() => {
                navNavigator("ContactUs");
                Navigate("/ContactUs");
              }}
            >
              <span>Contact Us</span>{" "}
            </button>
          </div>
        )}
      </div>
      {App.blurredBackgroundOverlayStatus === "visible" && (
        <div
          id="transperentBackgroundOverlay"
          onClick={() => {
            setNavMenu(false);
            App.setBlurredBackgroundOverlayStatus("hidden");
            App.setNavServicesDropMenu(false);
            App.lightTheme();
          }}
        ></div>
      )}{" "}
      <Outlet />
    </>
  );
};

export default MainNavBar;
