import { FaEnvelope, FaLinkedin } from "react-icons/fa";
import { useContext, useEffect } from "react";
import { FaSquareXTwitter } from "react-icons/fa6";
import { app } from "../App";
import { UMF } from "./specialComponents";
import { TrustedPartners } from "./specialComponents";
import { team_membersS } from "./bitImg";

const About = () => {
  const App = useContext(app);
  useEffect(() => {
    App.setCurrentPage("About");
  }, []);
  const isElementVisible = (el) => {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  function checkElementVisibility() {
    const abre7 = document.getElementById("abre7");
    const abre8 = document.getElementById("abre8");
    if (abre7 !== null && abre8 !== null) {
      if (isElementVisible(abre7)) {
        document.getElementById("abre11").style = "display:block";
      }
      if (isElementVisible(abre8)) {
        document.getElementById("abre14").style = "display:flex";
        document.getElementById("abre15").style = "display:flex";
        document.getElementById("abre16").style = "display:flex";
        document.getElementById("abre17").style = "display:flex";
      }
    }
  }

  window.addEventListener("scroll", checkElementVisibility);
  useEffect(() => {
    checkElementVisibility();
  }, []);
  return (
    <>
      <div id="ge1">
        <h1 id="abre1" className="ge2">
          About Us
        </h1>
        <p id="abre2">
          We are a leading tech company in Africa offering tech recruiting,
          tech startup studio, and world-class tech education. Our mission is to
          source Africa's talent and innovation to the world. At XAPIC, we are
          dedicated to being the best in the industry, delivering unmatched IT
          consulting, staffing, training, and technical recruitment services to
          empower your success in Africa and beyond. We are a technology
          workforce development company with a clear vision and mission to
          revolutionize the tech sector while nurturing African talent for
          global opportunities.
        </p>
        <div id="abre3">
          <img alt="" src={team_membersS} id="abre4" />
        </div>
        <div id="abre29">
          {" "}
          <TrustedPartners />
        </div>
      </div>
      <div id="abre6" className="row">
        <div className="col-sm-12 col-md-7" id="abre7">
          <div id="abre11">
            {" "}
            <h1 className="ge2" id="abre9">
              Our Story
            </h1>
            <p id="abre10">
              XAPIC Technologies reshapes African tech, empowering businesses
              with solutions and providing a global platform for African talent.
              Committed to tech excellence and innovation, we are a dedicated
              workforce development powerhouse partnering with global
              businesses.
            </p>
          </div>
        </div>
        <div className="col-sm-12 col-md-5" id="abre8">
          <div id="abre12">
            <div id="abre14">
              <img alt="" src={require("../images/st1.png")} id="abre18" />
            </div>
            <div id="abre15">
              <img alt="" src={require("../images/st3.png")} id="abre18" />
            </div>
          </div>

          <div id="abre13">
            <div id="abre16">
              {" "}
              <img alt="" src={require("../images/st2.png")} id="abre18" />
            </div>
            <div id="abre17">
              {" "}
              <img alt="" src={require("../images/st4.png")} id="abre18" />
            </div>
          </div>
        </div>
        <div id="abre19">
          <div className="flexCenter" id="abre30">
            <h1 id="abre30">Meet The Team Members</h1>
          </div>
          <div id="abre20">
            {" "}
            {App.teamMembers.map((value, index) => (
              <div id="abre21" key={index}>
                <div id="abre22">
                  <img alt="" src={value.profileImg} id="abre23" />
                </div>
                <div id="abre26">
                  {" "}
                  <p id="abre24">{value.fullName}</p>
                  <p id="abre25">{value.role}</p>
                  <p id="abre27">
                    {value?.linkedIn && (
                      <a href={value.linkedIn} id="ge14">
                        {" "}
                        <button id="abre28">
                          <FaLinkedin />
                        </button>
                      </a>
                    )}
                    {value?.twitter && (
                      <a href={value.twitter} id="ge14">
                        {" "}
                        <button id="abre28">
                          <FaSquareXTwitter />
                        </button>
                      </a>
                    )}

                    {value?.mail && <a href={`mailto:${value?.mail}`} id="ge14">
                      {" "}
                      <button id="abre28">
                        <FaEnvelope />
                      </button>
                    </a>}
                  </p>
                </div>
              </div>
            ))}{" "}
          </div>
        </div>
      </div>
      <div>
        <UMF />
      </div>
    </>
  );
};
export default About;
