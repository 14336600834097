import { useContext, useEffect, useState } from "react";
import { RiArrowRightDoubleLine } from "react-icons/ri";
import { TrustedPartners } from "./specialComponents";
import { FaSquareXTwitter } from "react-icons/fa6";
import { app } from "../App";
import { UMF } from "./specialComponents";
import { op1, op2, op3, op4, op5 } from "./bitImg";
import { useNavigate } from "react-router-dom";
import { FaEnvelope, FaLinkedin } from "react-icons/fa";
const Portfolio = () => {
  useEffect(() => {
    App.setCurrentPage("Portfolio");
  }, []);
  const Navigate = useNavigate();
  const App = useContext(app);
  const [selectedPortfolioService, setSelectedPortfolioService] =
    useState("All");
  return (
    <>
      <div id="pfre1">
        <div className="row">
          {" "}
          <div id="pfre2" className="col-sm-12 col-lg-4 ">
            <h1 id="pfre4">Our Portfolio</h1>
            <p id="pfre5">Our Portfolio of Excellence</p>
          </div>
          <div id="pfre3" className=" col-sm-12 col-lg-8">
            <div id="pfre6" className="row">
              <div id="pfre7" className="col-sm-12 col-lg-1">
                {selectedPortfolioService === "All" && (
                  <button
                    id="pfre8"
                    onClick={() => {
                      setSelectedPortfolioService("All");
                    }}
                  >
                    All
                  </button>
                )}
                {selectedPortfolioService !== "All" && (
                  <button
                    id="pfre9"
                    onClick={() => {
                      setSelectedPortfolioService("All");
                    }}
                  >
                    All
                  </button>
                )}
              </div>
              <div id="pfre7" className="col-sm-12 col-lg-3">
                {selectedPortfolioService === "TrainingPrograms" && (
                  <button
                    id="pfre8"
                    onClick={() => {
                      setSelectedPortfolioService("TrainingPrograms");
                    }}
                  >
                    {" "}
                    Training Programs
                  </button>
                )}
                {selectedPortfolioService !== "TrainingPrograms" && (
                  <button
                    id="pfre9"
                    onClick={() => {
                      setSelectedPortfolioService("TrainingPrograms");
                      Navigate("/ITTraining");
                      App.instantScrollToTop();
                    }}
                  >
                    {" "}
                    Training Programs
                  </button>
                )}
              </div>{" "}
              <div id="pfre7" className="col-sm-12 col-lg-3">
                {selectedPortfolioService ===
                  "Staffing&ContractingSolutions" && (
                  <button
                    id="pfre8"
                    onClick={() => {
                      setSelectedPortfolioService(
                        "Staffing&ContractingSolutions"
                      );
                    }}
                  >
                    {" "}
                    Staffing & Contracting Solutions
                  </button>
                )}
                {selectedPortfolioService !==
                  "Staffing&ContractingSolutions" && (
                  <button
                    id="pfre9"
                    onClick={() => {
                      setSelectedPortfolioService(
                        "Staffing&ContractingSolutions"
                      );
                      Navigate("/Contracting");
                      App.instantScrollToTop();
                    }}
                  >
                    {" "}
                    Staffing & Contracting Solutions
                  </button>
                )}
              </div>{" "}
              <div id="pfre7" className="col-sm-12 col-lg-3">
                {selectedPortfolioService === "TalentSourcing" && (
                  <button
                    id="pfre8"
                    onClick={() => {
                      setSelectedPortfolioService("TalentSourcing");
                    }}
                  >
                    Talent Sourcing
                  </button>
                )}
                {selectedPortfolioService !== "TalentSourcing" && (
                  <button
                    id="pfre9"
                    onClick={() => {
                      setSelectedPortfolioService("TalentSourcing");
                      Navigate("/TalentSourcing");
                      App.instantScrollToTop();
                    }}
                  >
                    Talent Sourcing
                  </button>
                )}
              </div>{" "}
              <div id="pfre7" className="col-sm-12 col-lg-2">
                {selectedPortfolioService === "ITConsultingServices" && (
                  <button
                    id="pfre8"
                    onClick={() => {
                      setSelectedPortfolioService("ITConsultingServices");
                    }}
                  >
                    {" "}
                    IT Consulting Services
                  </button>
                )}
                {selectedPortfolioService !== "ITConsultingServices" && (
                  <button
                    id="pfre9"
                    onClick={() => {
                      setSelectedPortfolioService("ITConsultingServices");
                      Navigate("/ITConsulting");
                      App.instantScrollToTop();
                    }}
                  >
                    {" "}
                    IT Consulting Services
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="pfre11">
        <div id="pfre10">
          <div className="row">
            <div
              className="col-sm-12 col-lg-6"
              id="pfre12"
              onClick={() => {
                Navigate("/About");
                setTimeout(() => {
                  window.scroll({
                    top: document.documentElement.scrollHeight,
                    left: 0,
                    behavior: "instant",
                  });
                }, 10);
                setTimeout(() => {
                  var element = document.getElementById("abre19");
                  if (element !== undefined) {
                    element.scrollIntoView({ behavior: "auto" });
                  }
                }, 15);
              }}
              style={{
                backgroundImage: `url(${op1})`,
              }}
            >
              {" "}
              <div id="pfre22">
                <p id="pfre23">
                  <RiArrowRightDoubleLine />
                </p>{" "}
                <p id="pfre24">Teams</p>
              </div>
            </div>
            <div className="col-sm-12 col-lg-6" id="pfre13">
              <div
                id="pfre16"
                onClick={() => {
                  Navigate("/ITTraining");
                  App.instantScrollToTop();
                }}
              >
                {" "}
                <div
                  id="pfre18"
                  style={{
                    backgroundImage: `url(${op5})`,
                  }}
                >
                  <div id="pfre22">
                    <p id="pfre23">
                      <RiArrowRightDoubleLine />
                    </p>{" "}
                    <p id="pfre24">Training Programs</p>
                  </div>
                </div>
                <div
                  id="pfre19"
                  style={{
                    backgroundImage: `url(${op3})`,
                  }}
                  onClick={() => {
                    Navigate("/ITConsulting");
                    App.instantScrollToTop();
                  }}
                >
                  <div id="pfre22">
                    <p id="pfre23">
                      <RiArrowRightDoubleLine />
                    </p>
                    <p id="pfre24">IT Consulting Services</p>
                  </div>
                </div>
              </div>
              <div id="pfre17">
                {" "}
                <div
                  id="pfre20"
                  style={{
                    backgroundImage: `url(${op4})`,
                  }}
                  onClick={() => {
                    Navigate("/Contracting");
                    App.instantScrollToTop();
                  }}
                >
                  <div id="pfre22">
                    <p id="pfre23">
                      <RiArrowRightDoubleLine />
                    </p>
                    <p id="pfre24">Staffing and contracting</p>
                  </div>
                </div>
                <div
                  id="pfre21"
                  style={{
                    backgroundImage: `url(${op2})`,
                  }}
                  onClick={() => {
                    Navigate("/TalentSourcing");
                    App.instantScrollToTop();
                  }}
                >
                  <div id="pfre22">
                    <p id="pfre23">
                      <RiArrowRightDoubleLine />
                    </p>
                    <p id="pfre24">Talent sourcing</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="pfre25">
        <h1 className="ge2" id="pfre26">
          Our Partners:
        </h1>
        <TrustedPartners />
        <div className="flexCenter">
          {" "}
          <div className="row" id="pfre27">
            <div className="col-sm-12 col-lg-7" id="pfre28">
              <img alt="" src={require("../images/Union 1.png")} id="pfre30" />
            </div>
            <div className="col-sm-12 col-lg-5" id="pfre29">
              <h1 className="ge2">Why Choose Us?</h1>
              <p id="pfre31">
                XAPIC is a leading tech company in Africa offering tech
                recruiting, tech startup studio, and world-class tech education.
                Our mission is to source Africa's talent and innovation to the
                world.{" "}
              </p>

              <div id="pfre33">
                <div id="pfre32">
                  <div id="pfre34"></div>
                  <p id="pfre35">7k+</p>
                  <p id="pfre36">Satisfied Clients</p>
                </div>
                <div id="pfre32">
                  {" "}
                  <div id="pfre37"></div>
                  <p id="pfre35">30+</p>
                  <p id="pfre36">IT Professionals</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flexColumnCenter" id="pfre41">
          <h1 id="pfre38">Team Leads Experts</h1>
          <div id="pfre39">
            {App.teamLeads.map((value, index) => (
              <div id="pfre40" key={index}>
                <div id="pfre42">
                  <img alt="" src={value.profileImg} id="pfre43" />
                </div>
                <h1 id="pfre44">{value.fullName}</h1>
                <p id="pfre45"> {value.team}</p>
                <p id="pfre46">{value.briefTeamInfo}</p>
                <div className="flexCenter">
                  {value.linkedIn && (
                    <a href={value.linkedIn} id="ge14">
                      {" "}
                      <button type="button" id="pfre47">
                        <FaLinkedin />
                      </button>
                    </a>
                  )}
                  {value.twitter && (
                    <a href={value.twitter} id="ge14">
                      {" "}
                      <button type="button" id="pfre47">
                        <FaSquareXTwitter />
                      </button>
                    </a>
                  )}
                  {value.mail && (
                    <a href={`mailto:${value.mail}`} id="ge14">
                      <button type="button" id="pfre47">
                        <FaEnvelope />
                      </button>
                    </a>
                  )}
                </div>
              </div>
            ))}{" "}
          </div>
        </div>
      </div>
      <UMF />
    </>
  );
};

export default Portfolio;
