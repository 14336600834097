import { useContext, useEffect, useState } from "react";
import { ImOffice } from "react-icons/im";
import { FiArrowRight } from "react-icons/fi";
import { BiSolidError } from "react-icons/bi";
import axios from "axios";

import {
  FaWhatsappSquare,
  FaEnvelope,
  FaLinkedinIn,
  FaInstagram,
  FaFacebookF,
  FaCheckCircle,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { app } from "../App";

export const UsersComment = () => {
  const [commentsCarouselNumber, setCommentsCarouselNumber] = useState(1);
  function isElementVisible(el) {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  function checkElementVisibility() {
    const ucre2 = document.getElementById("ucre2");
    if (ucre2 !== null) {
      if (isElementVisible(ucre2)) {
        document.getElementById("ucre8").style = "display:flex";
        document.getElementById("ucre7").style = "display:block";
        document.getElementById("ucre17").style = "display:flex";
      }
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", checkElementVisibility);
  }, []);
  const commentCarouselScroll = () => {
    const carousel = document.getElementById("ucre8");
    const margin = carousel.offsetWidth / 10;
    const carouselElements = document.getElementById("ucre9");

    let scrollStop;
    carousel.addEventListener("scroll", (e) => {
      clearTimeout(scrollStop);
      scrollStop = setTimeout(() => {
        if (
          carousel.scrollLeft > carouselElements.offsetWidth + margin &&
          carousel.scrollLeft < carouselElements.offsetWidth + margin
        ) {
          setCommentsCarouselNumber(2);
        } else if (
          carousel.scrollLeft >
            carouselElements.offsetWidth +
              (carouselElements.offsetWidth + margin * 2) &&
          carousel.scrollLeft < carouselElements.offsetWidth * 2
        ) {
          setCommentsCarouselNumber(3);
        } else if (
          carousel.scrollLeft >
          carouselElements.offsetWidth * 2 + carouselElements.offsetWidth
        ) {
          setCommentsCarouselNumber(4);
        } else {
          if (
            carousel.scrollLeft + margin <
            carouselElements.offsetWidth + margin
          ) {
            setCommentsCarouselNumber(1);
          } else if (
            carousel.scrollLeft + margin * 2 <
            carouselElements.offsetWidth * 2 + margin * 3
          ) {
            setCommentsCarouselNumber(2);
          } else if (
            carousel.scrollLeft + margin * 3 <
            carouselElements.offsetWidth * 3
          ) {
            setCommentsCarouselNumber(3);
          }
        }
      }, 50);
    });
  };

  const commentNavigation = (num) => {
    const carousel = document.getElementById("ucre8");
    const margin = carousel.offsetWidth / 10;
    const carouselElements = document.getElementById("ucre9");

    if (num === 1) {
      carousel.scrollLeft = 0;
      setCommentsCarouselNumber(1);
    } else if (num === 2) {
      carousel.scrollLeft = carouselElements.offsetWidth * 1 + margin * 1;
      setCommentsCarouselNumber(2);
    } else if (num === 3) {
      carousel.scrollLeft = carouselElements.offsetWidth * 2 + margin * 2;
      setCommentsCarouselNumber(3);
    } else if (num === 4) {
      carousel.scrollLeft = carouselElements.offsetWidth * 3 + margin * 3;
      setCommentsCarouselNumber(4);
    }
  };
  const seeMore = () => {
    if (commentsCarouselNumber === 1) {
      commentNavigation(2);
    }
    if (commentsCarouselNumber === 2) {
      commentNavigation(3);
    }
    if (commentsCarouselNumber === 3) {
      commentNavigation(4);
    }
    if (commentsCarouselNumber === 4) {
      commentNavigation(1);
    }
  };
  return (
    <>
      <div id="ucre18">
        <div id="ucre1" className="row">
          <div className="col-lg-6 col-sm-12" id="ucre2">
            <div id="ucre7">
              {" "}
              <h1 id="ucre4">What Our Clients Say</h1>
              <p id="ucre5">
                We are a technology workforce development company that works
                with businesses to hire the best personnel and engage in
                workforce development. We also assist people in learning
                advanced technology skills digitally.
              </p>
              <button
                type="button"
                id="ucre6"
                className="ucre21b"
                onClick={seeMore}
              >
                <span className="ucre21"> See More </span> <FiArrowRight />
              </button>
            </div>
          </div>
          <div className="col-lg-6 col-sm-12" id="ucre3">
            <div id="ucre8" onScroll={commentCarouselScroll}>
              <div id="ucre9">
                <div id="ucre20" className="co1">
                  G
                </div>{" "}
                <h3 id="ucre11">Graham</h3>
                <p id="ucre12">
                  The level of professionalism and communication we got from
                  their team is superb. All questions and concerns we had were
                  addressed promptly, and they consistently provided exception
                  results just to ensure our utmost satisfaction.
                </p>{" "}
              </div>
              <div id="ucre9">
                <div id="ucre20" className="co2">
                  S
                </div>

                <h3 id="ucre11">Sophie </h3>
                <p id="ucre12">
                  The moment I engaged with xapic, it was clear they understood
                  our unique needs and were able to tailor solutions that
                  exceeded our expectations. Their attention to detailed and
                  problem-solving abilities are truly commendable.
                </p>
              </div>
              <div id="ucre9">
                <div id="ucre20" className="co3">
                  A
                </div>{" "}
                <h3 id="ucre11">Adekunle</h3>
                <p id="ucre12">
                  What stood out to me the most was their commitment to our
                  project's success. They invested time in understanding our
                  challenges and worked relentlessly to ensure the solution not
                  only solved those problems but also provided scalability for
                  our future growth .
                </p>
              </div>
              <div id="ucre19">
                <div id="ucre20" className="co4">
                  M
                </div>{" "}
                <h3 id="ucre11">Micheal</h3>
                <p id="ucre12">
                  Partnering with xapic tech has driven our business to the next
                  level. Their approach and dedication to our progress were
                  unmatched. I look forward to many more successful
                  collaborations with their team in the nearest future.
                </p>
              </div>
            </div>
            <div id="ucre13">
              <div className="flexCenter" id="ucre17">
                {commentsCarouselNumber === 1 && (
                  <button type="button" id="ucre14"></button>
                )}
                {commentsCarouselNumber !== 1 && (
                  <button
                    type="button"
                    id="ucre15"
                    onClick={() => {
                      commentNavigation(1);
                    }}
                  ></button>
                )}

                {commentsCarouselNumber === 2 && (
                  <button type="button" id="ucre14"></button>
                )}
                {commentsCarouselNumber !== 2 && (
                  <button
                    type="button"
                    id="ucre15"
                    onClick={() => {
                      commentNavigation(2);
                    }}
                  ></button>
                )}
                {commentsCarouselNumber === 3 && (
                  <button type="button" id="ucre14"></button>
                )}
                {commentsCarouselNumber !== 3 && (
                  <button
                    type="button"
                    id="ucre15"
                    onClick={() => {
                      commentNavigation(3);
                    }}
                  ></button>
                )}
                {commentsCarouselNumber === 4 && (
                  <button type="button" id="ucre14"></button>
                )}
                {commentsCarouselNumber !== 4 && (
                  <button
                    type="button"
                    id="ucre15"
                    onClick={() => {
                      commentNavigation(4);
                    }}
                  ></button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const MailSubscription = () => {
  const [mailListEmail, setMailListEmail] = useState("");
  const [invalidInput, setInvalidInput] = useState("none");
  const [invalidInputWarningText, setInvalidInputWarningText] =
    useState("none");
  const [pendingSubmission, setPendingSubmission] = useState(false);
  const App = useContext(app);
  const submitEmail = () => {
    if (mailListEmail === "") {
      setInvalidInput("mailListEmail");
      setInvalidInputWarningText("emptyEmail");
    } else if (!mailListEmail.endsWith("@gmail.com")) {
      setInvalidInput("mailListEmail");
      setInvalidInputWarningText("invalidEmail");
    } else {
      setPendingSubmission(true);
      setInvalidInput("none");

      axios
        .post(`https://xapicapi.onrender.com/xapicMailingList`, {
          email: mailListEmail,
        })
        .then(
          (res) => {
            App.setDarkBackgroundOverlay("visible");
            App.setPopUpMessage("succesfullyAddedToMailingList");
            App.darkTheme();
            setPendingSubmission(false);
            setMailListEmail("");
          },
          (error) => {
            App.darkTheme();
            App.setDarkBackgroundOverlay("visible");
            App.setPopUpMessage("errorAddingEmailToMailingList");
            setPendingSubmission(false);
          }
        );
    }
  };

  return (
    <>
      {App.popupMessage === "succesfullyAddedToMailingList" && (
        <div id="ge8">
          <h5 id="ge9">
            Approved{" "}
            <span id="ge12">
              <FaCheckCircle />
            </span>
          </h5>
          <p id="ge10">
            Congratulations! You've successfully joined our email list. We're
            thrilled to have you on board. stay tuned for a wealth of insightful
            content ,exciting updates, exclusive offers, and valuable content
            straight to your inbox.
            <br />
            Welcome to the Xappy family.
          </p>
          <button
            id="ge11"
            type="button"
            onClick={() => {
              App.setDarkBackgroundOverlay("hidden");
              App.setPopUpMessage("none");
              App.lightTheme();
            }}
          >
            close
          </button>
        </div>
      )}
      {App.popupMessage === "errorAddingEmailToMailingList" && (
        <div id="ge8">
          <h5 id="ge9">
            Error{" "}
            <span id="ge13">
              {" "}
              <BiSolidError />
            </span>
          </h5>
          <p id="ge10">
            We regret to inform you that there was an error processing your
            request to join our mailing list. We apologize for any inconvenience
            this may have caused. Be sure to have a stable internet connection
            and try again in a bit,if the error persist contact our support team
            directly at info@gmail.com
            <br />
            Wishing you a xappy day.
          </p>
          <button
            id="ge11"
            type="button"
            onClick={() => {
              App.setDarkBackgroundOverlay("hidden");
              App.setPopUpMessage("none");
              App.lightTheme();
            }}
          >
            close
          </button>
        </div>
      )}
      <div id="msre1">
        <div id="msre2">
          <div id="msre5">
            {" "}
            <h1 id="msre3">Join Our Mailing List</h1>
            <p id="msre4">
              Be the first to discover available opportunities, upcoming
              events,webinars and many more.
            </p>
            <div id="msre12">
              <div id="msre8">
                {invalidInput === "none" && (
                  <input
                    type="email"
                    id="msre6"
                    className="msreI"
                    value={mailListEmail}
                    onChange={(e) => {
                      setMailListEmail(e.target.value);
                    }}
                    placeholder="YOUR EMAIL ADDRESS"
                  />
                )}
                {invalidInput === "mailListEmail" && (
                  <>
                    {" "}
                    <input
                      type="email"
                      id="msre10"
                      className="msreI"
                      value={mailListEmail}
                      onChange={(e) => {
                        setMailListEmail(e.target.value);
                      }}
                      placeholder="YOUR EMAIL ADDRESS"
                    />
                  </>
                )}
              </div>
              <div id="msre9">
                {!pendingSubmission && (
                  <button type="submit" id="msre7" onClick={submitEmail}>
                    SUBSCRIBE
                  </button>
                )}
                {pendingSubmission && (
                  <button type="button" id="msre7">
                    <span className="spinner-border" role="status"></span>
                  </button>
                )}
              </div>
            </div>
            {invalidInputWarningText === "emptyEmail" && (
              <p id="msre11">Email address cannot be empty</p>
            )}
            {invalidInputWarningText === "invalidEmail" && (
              <p id="msre11">
                Enter a valid email address ending with @gmail.com
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export const TrustedPartners = () => {
  return (
    <>
      <div className="flexColumnCenter" id="abre5">
        <p className="ge4">
          Our Talents are trusted by 100+ Companies around the World
        </p>
        <p className="ge5">
          <small>Our Talents are trusted by 100+ Companies</small>
          <small> around the World </small>
        </p>
        <div id="hmre9">
          <div id="hmre29">
            <img
              alt=""
              src={require("../images/iqviaLog.png")}
              className="ge3"
            />{" "}
            <img
              alt=""
              src={require("../images/binanceLogo.png")}
              className="ge3"
            />
            <img
              alt=""
              src={require("../images/ezoLogo.png")}
              className="ge3"
            />
            <img
              alt=""
              src={require("../images/cloudenlyLogo.png")}
              className="ge3"
            />
            <img
              alt=""
              src={require("../images/aleoLogo.png")}
              className="ge3"
            />
            <img
              alt=""
              src={require("../images/waterleauLogo.png")}
              className="ge3"
            />
            <img
              alt=""
              src={require("../images/dfsAfricaLogo.png")}
              className="ge3"
            />
            <img
              alt=""
              src={require("../images/edvesLogo.png")}
              className="ge3"
            />
            <img
              alt=""
              src={require("../images/iqviaLog.png")}
              className="ge3"
            />{" "}
            <img
              alt=""
              src={require("../images/binanceLogo.png")}
              className="ge3"
            />
            <img
              alt=""
              src={require("../images/ezoLogo.png")}
              className="ge3"
            />
            <img
              alt=""
              src={require("../images/cloudenlyLogo.png")}
              className="ge3"
            />
            <img
              alt=""
              src={require("../images/aleoLogo.png")}
              className="ge3"
            />
            <img
              alt=""
              src={require("../images/waterleauLogo.png")}
              className="ge3"
            />
            <img
              alt=""
              src={require("../images/dfsAfricaLogo.png")}
              className="ge3"
            />
            <img
              alt=""
              src={require("../images/edvesLogo.png")}
              className="ge3"
            />
            <img
              alt=""
              src={require("../images/iqviaLog.png")}
              className="ge3"
            />{" "}
            <img
              alt=""
              src={require("../images/binanceLogo.png")}
              className="ge3"
            />
            <img
              alt=""
              src={require("../images/ezoLogo.png")}
              className="ge3"
            />
            <img
              alt=""
              src={require("../images/cloudenlyLogo.png")}
              className="ge3"
            />
            <img
              alt=""
              src={require("../images/aleoLogo.png")}
              className="ge3"
            />
            <img
              alt=""
              src={require("../images/waterleauLogo.png")}
              className="ge3"
            />
            <img
              alt=""
              src={require("../images/dfsAfricaLogo.png")}
              className="ge3"
            />
            <img
              alt=""
              src={require("../images/edvesLogo.png")}
              className="ge3"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export const Footer = () => {
  const Navigate = useNavigate();
  const App = useContext(app);
  return (
    <>
      <div id="ftre1">
        <div id="ftre2">
          {" "}
          <div id="ftre3">
            <img
              alt=""
              src={require("../images/Xapic Logo - Full Colour with White  2.png")}
              id="ftre4"
            />
          </div>
          <ul id="ftre3">
            <li id="ftre5">
              {" "}
              <button id="ftre17">About Us</button>
            </li>
            <li id="ftre6">
              {" "}
              <button
                id="ftre16"
                onClick={() => {
                  Navigate("/About");
                  setTimeout(() => {
                    var element = document.getElementById("abre19");
                    if (element !== undefined) {
                      element.scrollIntoView({ behavior: "instant" });
                    }
                  }, 100);
                }}
              >
                Teams
              </button>
            </li>
            <li id="ftre6">
              {" "}
              <button
                id="ftre16"
                onClick={() => {
                  App.instantScrollToTop();
                  Navigate("/Careers");
                }}
              >
                Careers
              </button>
            </li>
          </ul>
          <ul id="ftre3">
            <li id="ftre5">
              {" "}
              <button id="ftre17">Resources</button>{" "}
            </li>

            <li id="ftre6">
              {" "}
              <button
                id="ftre16"
                onClick={() => {
                  var element = document.getElementById("msre1");
                  if (element !== undefined) {
                    element.scrollIntoView({ behavior: "instant" });
                  }
                  document.querySelector(".msreI").focus();
                }}
              >
                Newsletter
              </button>
            </li>
          </ul>{" "}
          <ul id="ftre3">
            <li id="ftre5">
              {" "}
              <button id="ftre17">Contact</button>
            </li>
            <li id="ftre6">
              <span id="ftre23">
                <ImOffice />
              </span>
              <br />{" "}
              <li id="ftre24">
                <b>Lagos :</b> 2nd floor, Leasing House, C & I Leasing Drive,
                Off Bisola Durosinmi Etti Drive, Off Admiralty way, Lekki Phase
                1, Lagos
              </li>
              <li id="ftre24">
                <b>Akure:</b> Xapic Technologies Building, Opposite State High
                Court, Arakale Road, Akure
              </li>
            </li>
            <li id="ftre6">
              <span id="ftre7">
                <span id="ftre15">
                  {" "}
                  <FaEnvelope />{" "}
                </span>
                info@xapictech.com{" "}
              </span>
              <span id="ftre7">
                <span id="ftre15">
                  <FaWhatsappSquare />
                </span>{" "}
                +2348167001859{" "}
              </span>
            </li>
          </ul>
          <div id="ftre3">
            <ul id="ftre8">
              <li id="ftre9">
                <a
                  href="https://m.facebook.com/XapicTechnologies?refid=13&__tn__=%2Cg"
                  id="ge14"
                >
                  <button id="ftre16">
                    <FaFacebookF />
                  </button>
                </a>
              </li>

              <li id="ftre9">
                {" "}
                <a
                  href="https://instagram.com/xapic.tech?igshid=MzRlODBiNWFlZA==="
                  id="ge14"
                >
                  <button id="ftre16">
                    <FaInstagram />
                  </button>
                </a>
              </li>
              <li id="ftre9">
                {" "}
                <a
                  href="https://www.linkedin.com/company/xapic-technologies/"
                  id="ge14"
                >
                  <button id="ftre16">
                    <FaLinkedinIn />
                  </button>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div id="ftre10">
          <button id="ftre18">Xapic @ 2023. All rights reserved.</button>
          <div id="ftre13">
            {" "}
            <button
              id="ftre12"
              onClick={() => {
                App.instantScrollToTop();
                Navigate("/PrivacyPolicy");
              }}
            >
              Privacy Policy
            </button>
            <button
              id="ftre12"
              onClick={() => {
                App.instantScrollToTop();
                Navigate("/TermsOfServices");
              }}
            >
              Terms of Service
            </button>
          </div>
        </div>
      </div>

      {/***************** small screen footer ***************** */}
      <div id="ftre22">
        <div id="ftre19">
          <div className="row">
            {" "}
            <div className="col-sm-6">
              {" "}
              <div id="ftre20">
                <img
                  alt=""
                  src={require("../images/Xapic Logo - Full Colour with White  2.png")}
                  id="ftre4"
                />
              </div>
              <ul id="ftre3">
                <li id="ftre5">
                  {" "}
                  <button id="ftre17">Contact</button>
                </li>
                <li id="ftre6">
                  <span id="ftre23">
                    <ImOffice />
                  </span>
                  <br />{" "}
                  <li id="ftre24">
                    <b>Lagos :</b> 2nd floor, Leasing House, C & I Leasing
                    Drive, Off Admiralty way,Lekki Phase 1, Lagos
                  </li>
                  <li id="ftre24">
                    <b>Akure:</b> Xapic Technologies Building, Opposite State
                    High Court, Arakale Road, Akure
                  </li>
                </li>
                <li id="ftre6">
                  <span id="ftre7">
                    <span id="ftre15">
                      {" "}
                      <FaEnvelope />{" "}
                    </span>
                    info@xapictech.com{" "}
                  </span>
                  <span id="ftre7">
                    <span id="ftre15">
                      <FaWhatsappSquare />
                    </span>{" "}
                    +2348167001859{" "}
                  </span>
                </li>
              </ul>
              <div id="ftre3">
                <ul id="ftre8">
                  <li id="ftre9">
                    {" "}
                    <a
                      href="https://m.facebook.com/XapicTechnologies?refid=13&__tn__=%2Cg"
                      id="ge14"
                    >
                      <button id="ftre16">
                        <FaFacebookF />
                      </button>
                    </a>
                  </li>

                  <li id="ftre9">
                    {" "}
                    <a
                      href="https://instagram.com/xapic.tech?igshid=MzRlODBiNWFlZA==="
                      id="ge14"
                    >
                      <button id="ftre16">
                        <FaInstagram />
                      </button>
                    </a>
                  </li>
                  <li id="ftre9">
                    {" "}
                    <a
                      href="https://www.linkedin.com/company/xapic-technologies/
"
                      id="ge14"
                    >
                      <button id="ftre16">
                        <FaLinkedinIn />
                      </button>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-6">
              {" "}
              <ul id="ftre3">
                <li id="ftre5">
                  {" "}
                  <button id="ftre17">About Us</button>
                </li>
                <li id="ftre6">
                  {" "}
                  <button
                    id="ftre16"
                    onClick={() => {
                      Navigate("/About");
                      setTimeout(() => {
                        var element = document.getElementById("abre19");
                        if (element !== undefined) {
                          element.scrollIntoView({ behavior: "instant" });
                        }
                      }, 100);
                    }}
                  >
                    Teams
                  </button>
                </li>
                <li id="ftre6">
                  {" "}
                  <button
                    id="ftre16"
                    onClick={() => {
                      App.instantScrollToTop();
                      Navigate("/Careers");
                    }}
                  >
                    Careers
                  </button>
                </li>
              </ul>
              <ul id="ftre3">
                <li id="ftre5">
                  {" "}
                  <button id="ftre17">Resources</button>{" "}
                </li>

                <li id="ftre6">
                  {" "}
                  <button
                    id="ftre16"
                    onClick={() => {
                      var element = document.getElementById("msre1");
                      if (element !== undefined) {
                        element.scrollIntoView({ behavior: "instant" });
                        document.querySelector(".msreI").focus();
                      }
                    }}
                  >
                    Newsletter
                  </button>
                </li>
              </ul>{" "}
            </div>{" "}
          </div>
        </div>
        <div id="ftre21">
          <button id="ftre18">Xapic @ 2023. All rights reserved.</button>
          <div id="ftre13">
            {" "}
            <button
              id="ftre12"
              onClick={() => {
                App.instantScrollToTop();
                Navigate("/PrivacyPolicy");
              }}
            >
              Privacy Policy
            </button>
            <button
              id="ftre12"
              onClick={() => {
                App.instantScrollToTop();
                Navigate("/TermsOfServices");
              }}
            >
              Terms of Service
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export const UMF = () => {
  return (
    <>
      <UsersComment />
      <MailSubscription />
      <Footer />
    </>
  );
};
