import { Outlet, useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { useContext } from "react";
import { app } from "../App";
export const TermsOfServicesNav = () => {
  const App = useContext(app);
  const Navigate = useNavigate();
  return (
    <>
      <div id="ppre1" className="fixed-top">
        <button
          id="ppre2"
          onClick={() => {
            App.instantScrollToTop();
            Navigate("/");
          }}
        >
          <IoIosArrowBack />{" "}
        </button>
        <span id="ppre3">Terms of services</span>
      </div>
      <Outlet />
    </>
  );
};

export const TermsOfServices = () => {
  return (
    <>
      <div id="ppre4">
        {" "}
        <h4 id="ppre5">Terms of Service for Xapic </h4>
        <p id="ppre5"> Effective Date:25,oct 2023</p>
        <p id="ppre6">
          Welcome to xapic-technologies, These Terms of Service govern your use
          of our services. By accessing or using our services, you agree to be
          bound by these Terms.
        </p>{" "}
        <h2 id="ppre7">1. Services</h2>
        <p id="ppre6">
          By accessing or using the services provided by Xapic , you agree to be
          bound by these Terms and any additional terms and policies referenced
          herein.
        </p>
        <h2 id="ppre7">2. User Responsibilities</h2>
        <p id="ppre6">You agree to:</p>
        <ul>
          <li id="ppre8">
            Not reverse engineer, decompile, or disassemble any part of our
            services.
          </li>
          <li id="ppre8">
            Not attempt to interfere with the integrity or security of our
            services.
          </li>
          <li id="ppre8">
            {" "}
            Not engage in any illegal, harmful, or unauthorized activities.
          </li>{" "}
          <li id="ppre8">
            Use the services in compliance with applicable laws and regulations.
          </li>{" "}
        </ul>
        <h2 id="ppre7">3. Intellectual Property</h2>
        <p id="ppre6">
          xapic retains all rights, title, and interest in and to the services,
          including all intellectual property rights.
        </p>{" "}
        <h2 id="ppre7">4. Third-Party Content and Links</h2>
        <p id="ppre6">
          Our services may contain links to third-party websites or content. We
          do not endorse or control these third-party sites or content, and we
          are not responsible for their accuracy, legality, or availability.
        </p>{" "}
        <h2 id="ppre7">5. Limitation of Liability</h2>
        <p id="ppre6">
          We are not liable for any indirect, incidental, special,
          consequential, or punitive damages arising out of your use of our
          services.
        </p>
        <h2 id="ppre7">6. Indemnification</h2>
        <p id="ppre6">
          You agree to indemnify and hold xapic-technologies harmless from any
          claims, damages, liabilities, costs, or expenses.
        </p>
        <h2 id="ppre7">7. Changes to Terms</h2>
        <p id="ppre6">
          We may update these Terms from time to time. The most current version
          will be posted on our website, and the effective date will be revised
          accordingly.
        </p>{" "}
        <h2 id="ppre7">8. Termination</h2>
        <p id="ppre6">
          We may terminate or suspend your access to the services at any time
          without prior notice or liability for any reason.
        </p>{" "}
        <h2 id="ppre7">9. Governing Law</h2>
        <p id="ppre6">
          These Terms shall be governed by and construed in accordance with the
          laws of [Jurisdiction].
        </p>
        <h2 id="ppre7">10. Entire Agreement</h2>
        <p id="ppre6">
          These Terms constitute the entire agreement between you and
          xapic-technologiesand supersede all prior agreements.
        </p>
        <h2 id="ppre7">11. Contact Us</h2>
        <p id="ppre6">
          If you have any questions or concerns about these Terms, please
          contact us at: <b>info@xapictech.com</b>
        </p>
      </div>
    </>
  );
};
