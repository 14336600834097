import { BsArrowRight } from "react-icons/bs";
import { UMF } from "./specialComponents";
import { useContext, useEffect } from "react";
import { app } from "../App";
import { useNavigate } from "react-router-dom";

export const Contracting = () => {
  useEffect(() => {
    checkElementVisibility();
  }, []);
  const Navigate = useNavigate();
  const App = useContext(app);
  useEffect(() => {
    App.setCurrentServicesPage("Contracting");
    App.setCurrentPage("Services");
  });
  const isElementVisible = (el) => {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  function checkElementVisibility() {
    const sere5 = document.getElementById("sere5");
    if (sere5 !== null) {
      if (isElementVisible(sere5)) {
        document.getElementById("sere8").style = "display:flex";
        document.getElementById("sere9").style = "display:flex";
        document.getElementById("sere10").style = "display:flex";
        document.getElementById("sere11").style = "display:flex";
      }
    }
  }

  window.addEventListener("scroll", checkElementVisibility);

  return (
    <>
      <div id="ge15">
        <div id="sere14">
          <div className="row" id="sere13">
            <div className="col-sm-12 col-lg-7" id="sere12">
              <div id="sere16">
                <h1 className="ge2">IT Contracting</h1>
                <p id="sere1">
                  Our contracting services offer flexible solutions to meet your
                  temporary or project-based IT staffing needs. We maintain a
                  pool of highly skilled and certified professionals ready to
                  join your team on a short-term basis. Whether you require
                  additional IT support during a major project or need
                  specialized expertise for a specific task, our contracting
                  services ensure that you have the right talent when you need
                  it.
                </p>
              </div>
              <div id="sere2">
                <button type="button" id="sere3">
                  Cybersecurity
                </button>
                <button type="button" id="sere3">
                  Cloud Computing
                </button>{" "}
                <button type="button" id="sere3">
                  Project Management
                </button>{" "}
                <button type="button" id="sere3">
                  Risk Management
                </button>{" "}
                <button type="button" id="sere3">
                  Data Analytics
                </button>
                <button type="button" id="sere3">
                  Business Intelligence
                </button>
              </div>
              <button
                type="button"
                id="sere4"
                onClick={() => {
                  Navigate("/ContactUs");
                  App.instantScrollToTop();
                }}
              >
                <span className="sere19"> Contact Us </span>
                <BsArrowRight />
              </button>
            </div>

            <div className="col-sm-12 col-lg-5" id="sere5">
              <div id="sere6">
                <div id="sere8"></div>
                <div id="sere9">
                <img alt=""   src={require("../images/sere1.png")} id="sere15" />
                </div>
              </div>

              <div id="sere7">
                <div id="sere10">
                  {" "}
                  <img alt="" src={require("../images/sere2.png")} id="sere15" />
                </div>
                <div id="sere11"> </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <UMF />
      </div>
    </>
  );
};

export const ITConsulting = () => {
  useEffect(() => {
    checkElementVisibility();
  }, []);
  const Navigate = useNavigate();
  const App = useContext(app);
  useEffect(() => {
    App.setCurrentServicesPage("ITConsulting");
    App.setCurrentPage("Services");
  });
  const isElementVisible = (el) => {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  function checkElementVisibility() {
    const sere5 = document.getElementById("sere5");
    if (sere5 !== null) {
      if (isElementVisible(sere5)) {
        document.getElementById("sere8").style = "display:flex";
        document.getElementById("sere9").style = "display:flex";
        document.getElementById("sere10").style = "display:flex";
        document.getElementById("sere11").style = "display:flex";
      }
    }
  }

  window.addEventListener("scroll", checkElementVisibility);

  return (
    <>
      <div id="ge15">
        <div id="sere14">
          <div className="row" id="sere13">
            <div className="col-sm-12 col-lg-7" id="sere12">
              <div id="sere16">
                <h1 className="ge2">IT Consulting</h1>
                <p id="sere1">
                  Our IT consulting services are designed to provide your
                  business with expert guidance and strategic insights. We have
                  a team of seasoned IT professionals who can assess your
                  current technology infrastructure, identify areas for
                  improvement, and develop a tailored IT strategy to align with
                  your business goals. Whether it's optimizing your IT budget,
                  implementing new technologies, or enhancing cybersecurity, our
                  consultants are here to assist you every step of the way.
                </p>
              </div>
              <div id="sere2">
                <button type="button" id="sere3">
                  Cybersecurity
                </button>
                <button type="button" id="sere3">
                  Cloud Computing
                </button>{" "}
                <button type="button" id="sere3">
                  Project Management
                </button>{" "}
                <button type="button" id="sere3">
                  Risk Management
                </button>{" "}
                <button type="button" id="sere3">
                  Data Analytics
                </button>
                <button type="button" id="sere3">
                  Business Intelligence
                </button>
              </div>
              <button
                type="button"
                id="sere4"
                onClick={() => {
                  Navigate("/ContactUs");
                  App.instantScrollToTop();
                }}
              >
                <span className="sere19"> Contact Us </span> <BsArrowRight />
              </button>
            </div>

            <div className="col-sm-12 col-lg-5" id="sere5">
              <div id="sere18">
                <div id="sere10">
                  {" "}
                  <img alt="" src={require("../images/sere6.png")} id="sere15" />
                </div>
                <div id="sere11"> </div>
              </div>
              <div id="sere17">
                <div id="sere8"></div>
                <div id="sere9">
                  <img alt="" src={require("../images/sere5.png")} id="sere15" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <UMF />
      </div>
    </>
  );
};

export const TalentSourcing = () => {
  useEffect(() => {
    checkElementVisibility();
  }, []);
  const Navigate = useNavigate();
  const App = useContext(app);
  useEffect(() => {
    App.setCurrentServicesPage("TalentSourcing");
    App.setCurrentPage("Services");
  });

  const isElementVisible = (el) => {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  function checkElementVisibility() {
    const sere5 = document.getElementById("sere5");
    if (sere5 !== null) {
      if (isElementVisible(sere5)) {
        document.getElementById("sere8").style = "display:flex";
        document.getElementById("sere9").style = "display:flex";
        document.getElementById("sere10").style = "display:flex";
        document.getElementById("sere11").style = "display:flex";
      }
    }
  }

  window.addEventListener("scroll", checkElementVisibility);

  return (
    <>
      <div id="ge15">
        <div id="sere14">
          <div className="row" id="sere13">
            <div className="col-sm-12 col-lg-7" id="sere12">
              <div id="sere16">
                <h1 className="ge2">Talent Sourcing</h1>
                <p id="sere1">
                  Finding the right IT talent for your organization can be
                  challenging. That's where our talent sourcing services come
                  in. We have an extensive network of IT professionals, from
                  developers and engineers to cybersecurity experts and project
                  managers. Our rigorous screening and selection process ensures
                  that you get access to top-tier talent that matches your
                  requirements. Let us help you build a strong and capable IT
                  team.
                </p>
              </div>
              <div id="sere2">
                <button type="button" id="sere3">
                  Human Resource
                </button>
                <button type="button" id="sere3">
                  Technical Role
                </button>{" "}
                <button type="button" id="sere3">
                  Non-Technical Role
                </button>{" "}
                <button type="button" id="sere3">
                  Recruitment
                </button>{" "}
                <button type="button" id="sere3">
                  Team Growth
                </button>
              </div>
              <button
                type="button"
                id="sere4"
                onClick={() => {
                  Navigate("/ContactUs");
                  App.instantScrollToTop();
                }}
              >
                <span className="sere19"> Contact Us </span> <BsArrowRight />
              </button>
            </div>

            <div className="col-sm-12 col-lg-5" id="sere5">
              <div id="sere6">
                <div id="sere8"></div>
                <div id="sere9">
                  <img alt="" src={require("../images/sere4.png")} id="sere15" />
                </div>
              </div>

              <div id="sere7">
                <div id="sere10">
                  {" "}
                  <img alt="" src={require("../images/sere3.png")} id="sere15" />
                </div>
                <div id="sere11"> </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <UMF />
      </div>
    </>
  );
};

export const ITTraining = () => {
  const Navigate = useNavigate();
  const App = useContext(app);
  useEffect(() => {
    App.setCurrentServicesPage("ITTraining");
    App.setCurrentPage("Services");
  });

  const isElementVisible = (el) => {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  function checkElementVisibility() {
    const sere5 = document.getElementById("sere5");
    if (sere5 !== null) {
      if (isElementVisible(sere5)) {
        document.getElementById("sere8").style = "display:flex";
        document.getElementById("sere9").style = "display:flex";
        document.getElementById("sere10").style = "display:flex";
        document.getElementById("sere11").style = "display:flex";
      }
    }
  }
  useEffect(() => {
    checkElementVisibility();
  }, []);
  window.addEventListener("scroll", checkElementVisibility);

  return (
    <>
      <div id="ge15">
        <div id="sere14">
          <div className="row" id="sere13">
            <div className="col-sm-12 col-lg-7" id="sere12">
              <div id="sere16">
                <h1 className="ge2">IT Training</h1>
                <p id="sere1">
                  In the ever-evolving world of technology, continuous learning
                  is essential. Our IT training programs are designed to upskill
                  your existing IT staff or provide individuals with the
                  knowledge they need to excel in their careers. We offer a wide
                  range of training courses, from programming languages and
                  cloud computing to cybersecurity and IT project management.
                  Invest in the development of your IT team with our
                  comprehensive training solutions.
                </p>
              </div>
              <div id="sere2">
                <button type="button" id="sere3">
                  Technical Role
                </button>
                <button type="button" id="sere3">
                  Non-Technical Role
                </button>{" "}
                <button type="button" id="sere3">
                  Product Designer
                </button>{" "}
                <button type="button" id="sere3">
                  Human Resource
                </button>{" "}
                <button type="button" id="sere3">
                  Front-end/Back-end Development
                </button>
                <button type="button" id="sere3">
                  DevOps Engineer
                </button>
                <button type="button" id="sere3">
                  Data Analysts
                </button>
              </div>
              <a href="https://docs.google.com/forms/d/e/1FAIpQLScNgupG_d1SIdQkEFJDt7aH3dbG_jjmIDPLNm8RaL7XOyjoYg/viewform?usp=sharing">
                {" "}
                <button type="button" id="sere4">
                  <span className="sere19"> Apply </span>
                  <BsArrowRight />
                </button>
              </a>
            </div>

            <div className="col-sm-12 col-lg-5" id="sere5">
              <div id="sere18">
                <div id="sere10">
                  {" "}
                  <img
                    alt=""
                    src={require("../images/sere7.png")}
                    id="sere15"
                  />
                </div>
                <div id="sere11"> </div>
              </div>
              <div id="sere17">
                <div id="sere8"></div>
                <div id="sere9">
                  <img
                    alt=""
                    src={require("../images/sere8.png")}
                    id="sere15"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <UMF />
      </div>
    </>
  );
};
